import React from "react";
import styled, { css } from "styled-components";
import FrameComponent from "./components/FrameComponent";
import Features from "./components/Features";
import Apply from "./components/Apply";
import ConsumerMerchantItems from "./components/ConsumerMerchantItems";
import FeaturesContainer from "./components/FeaturesContainer";
import FeatureGrid from "./components/FeatureGrid";
import BenefitTitleContainer from "./components/BenefitTitleContainer";
import Footer from "./components/Footer";
import DreamsWithin from "./components/DreamsWithin";
// import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Prodcards from "./components/Prodcards";
import Thepower from "./components/Thepower";

const WebsiteContainer = styled.div`
  width: 100%; // Ensure the container covers the full width of the viewport
  min-height: 100vh; // Ensure the container covers the full height of the viewport
  position: relative;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
  letter-spacing: normal;
  box-sizing: border-box; // Include padding and border in the element's total width and height
  overflow-x: hidden; // Prevent horizontal scrolling

  @media (max-width: 768px) {
    padding: 0; // Adding padding for better view on mobile
  }
`;

const HeroSection = styled.section`
  position: relative; // Make it position relative to confine its children
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh; // Ensure minimum height is the full viewport height
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  // text-align: center;
  color: #fff;
  font-family: var(--heading-h6);

  @media (max-width: 768px) {
    padding: 1rem 0;
    font-size: 1.5rem;
  }
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;

  @media (max-width: 768px) {
    /* Make the video cover the full height and width of the viewport */
    position: fixed;
    width: 100%;
    height: 100vh;
    /* Maintain aspect ratio */
    object-fit: cover;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10%;
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  overflow: hidden;

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0%;
    padding: 1rem;
  }
`;
const HeroContentWrapper = styled.div`
  width: 35%;
  align-self: center;

  @media (max-width: 768px) {
    width: 0%;
    padding: 1rem;
    display: none;
  }
`;

const HeroContentImg = styled.img`
  width: 50%;
`;
const HeroContentWrapper2 = styled.div`
  align-self: end;
  justify-self: left;
  margin-top: 2rem;
  padding: 4rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
    align-self: center;
    justify-self: center;
    justify-content: center;
    padding: 1rem;
  }
`;

const HeroContentImg2 = styled.img`
  width: 100%;
`;
const HeroTitleText = styled.div`
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 6rem;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 4.5rem; // Increased font size for mobile
    text-align: center;
    padding-top: 1rem;
  }
`;

const HeroSubtitleText = styled.div`
  font-family: "DM Sans";
  font-weight: 900;
  font-size: 10rem;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 3rem; // Increased font size for mobile
  }
`;

const HeroTitle = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 5.5rem; // Increased font size for mobile
  }
`;

const HeroSubtitle = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  font-size: 1rem;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 2rem;
    text-align: center;
    font-size: 3.2rem; // Increased font size for mobile
  }
`;

const BenefitsSection = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on mobile */
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  padding: 2rem 1rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 2rem; /* Adjusted for mobile */
  color: #fff;
  font-family: var(--heading-h6);
  margin-top: 2rem; /* Adjusted margin for mobile */
  background-color: #fff;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
`;

const BenefitsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  gap: 1rem;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const BenefitsHeader = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column; /* Changed to column for better stacking on mobile */
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100%;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const BenefitsTitle = styled.div`
  width: 100%; /* Full width on mobile */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content */
  justify-content: center; /* Center content */
  gap: 1rem;
  max-width: 100%;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const TitleText = styled.div`
  margin-top: 0.5rem;
  text-align: left;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 3rem;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    font-size: 1.6rem;
    font-family: "DM Sans";
    text-align: center;
    font-weight: 500;
  }
`;

const FullWidthSection = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0; /* Optional: Add padding for better spacing */
  box-sizing: border-box;
`;

const Website = () => {
  return (
    <WebsiteContainer>
      {/* <Navbar /> */}
      <Header />
      <HeroSection>
        <HeroContent>
          <VideoBackground autoPlay loop muted playsInline>
            <source src="../video/Greattr_Video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBackground>
          <HeroTitle>
            <HeroTitleText>Small Loans for</HeroTitleText>
            <HeroSubtitleText>Big Aspirations</HeroSubtitleText>
          </HeroTitle>
          <HeroSubtitle>
            <TitleText>
              Transforming Lives with Seamless, <br /> Accessible Micro Finance
              Solutions for All.
            </TitleText>

            <HeroContentWrapper2>
              <a
                href="https://play.google.com/store/apps/details?id=com.greattr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HeroContentImg2
                  src="./playstorewhite.png"
                  alt="Hero Section Phone"
                />
              </a>
            </HeroContentWrapper2>
          </HeroSubtitle>
        </HeroContent>

        <HeroContentWrapper>
          <a
            href="https://play.google.com/store/apps/details?id=com.greattr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HeroContentImg src="./herosecphone.png" alt="Hero Section Phone" />
          </a>
        </HeroContentWrapper>
      </HeroSection>

      {/*  */}
      {/*  */}
      {/*  */}
      {/* <FullWidthSection>
        <FrameComponent />
      </FullWidthSection> */}
      <FullWidthSection>
        {/*  */}
        {/*  */}
        {/*  */}
        <DreamsWithin />

        <Prodcards />

        {/*  */}
        {/*  */}
        {/*  */}
        <Thepower />
        <Features />
        <Apply />

        <FeatureGrid />

        <BenefitTitleContainer />
        <Footer />
      </FullWidthSection>
    </WebsiteContainer>
  );
};

export default Website;
