import React, { useState, useEffect } from "react";
import "./DistSafetyCheckPersonalDetails.scss";
import greattrlogo from "../assets/logo.png";
import { GESRecords } from "../../Utilsx/Encrypt";
import axios from "../../components/utils/axiosConfig";
import { maskAadhaar } from "../utils/validations";
import { Spinner } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import {
  validateEmail,
  validateMobileNo,
  validatePanNo,
  validateAadhaarNo,
} from "../utils/validations";

const DistSafetyCheckPersonalDetails = () => {
  let apiEN = process.env.REACT_APP_API_apiEN;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setisLoading] = useState(false);
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log("savedState:", savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  console.log("state:", state);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [aadhaarNo, setAadhaarNo] = useState("");
  const [panNo, setPanNo] = useState("");
  const [mobileNo, setMobileNo] = useState(state?.MobileNumber || "");
  const [errors, setErrors] = useState({});
  const [personal_MobileNo, setpersonal_MobileNo] = useState(
    state?.MobileNumber || ""
  );
  const [landline, setLandline] = useState({
    stateCode: "",
    number: "",
  });

  const handleStateCodeChange = (e) => {
    console.log(landline);
    setLandline((prev) => ({ ...prev, stateCode: e.target.value }));
  };

  const handleNumberChange = (e) => {
    setLandline((prev) => ({ ...prev, number: e.target.value }));
  };

  const [dob, setDob] = useState({ day: "", month: "", year: "" });
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState(state?.email || "");

  const [addressState, setAddressState] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDob((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("INSIDE");
    if (!validateAadhaarNo(aadhaarNo)) {
      alert("Please enter a valid 12 digit Aadhaar number");
      return;
    }

    if (!validatePanNo(panNo)) {
      alert("Please enter a valid PAN number in the format ABCDE1234F");
      return;
    }

    if (!validateMobileNo(mobileNo)) {
      alert(
        "Please enter a valid 10 digit mobile number starting with 7, 8, or 9"
      );
      return;
    }

    if (!validateMobileNo(personal_MobileNo)) {
      alert(
        "Please enter a valid 10 digit personal mobile number starting with 7, 8, or 9"
      );
      return;
    }

    if (!validateEmail(email)) {
      alert("Please enter a valid email address");
      return;
    }

    let submitData = {
      FirstName: firstName,
      email: email,
      LastName: lastName,
      AadhaarNumber: maskAadhaar(aadhaarNo).replace(/-/g, ""),
      PAN: panNo,
      MobileNumber: state?.MobileNumber,
      personal_mobile: personal_MobileNo,
      landline_Number:
        landline.stateCode !== "" && landline.number !== ""
          ? landline.stateCode + "-" + landline.number
          : "",
      DateOfBirth: `${dob.year}-${dob.month}-${dob.day}`,
      Address: `${address}, ${city}, ${addressState}, ${pinCode}`,
    };
    let EncrytedSubmitData = GESRecords(submitData, apiEN);

    try {
      setisLoading(true);

      const route =
        state.role === "Dist"
          ? "/UpdateMerchantPersonalDetails"
          : "/UpdateDealerPersonalDetails";
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}${route}`,
        EncrytedSubmitData
      );
      if (response.data.duplicate) {
        alert("Mobile number already exists in the database.");
      } else {
        // alert(`${state.role} data inserted successfully.`);
        navigate("/safetycheck/DistSafetyCheckCompanyInfoOperator", {
          state: {
            ...state,
            role: state?.role,
            MobileNumber: state?.MobileNumber,
            personal_mobile: personal_MobileNo,
            landline_Number: landline.stateCode + "-" + landline.number,

            AadhaarNumber: aadhaarNo,
            email: email,
            // GreattrOperatorId: response.data.GreattrOperatorId,
          },
        });
      }
    } catch (error) {
      console.log(
        error.response?.data?.message ||
        "An error occurred while submitting the form."
      );
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div className="personal_company-info">
      <div className="personal_company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p>4 / 6</p>
        <h3 style={{ fontSize: 28 }}>Basic Information</h3>
        <h5 >
          Tell us a bit about yourself. Your personal information is crucial for
          verification purposes and will help us maintain a secure and
          trustworthy platform for all users.
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group1">
            <div className="indi-form-group">
              <label htmlFor="firstName" className="label-font">
                First Name (as per PAN)*
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="placeholder-font"
                value={firstName}
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="indi-form-group">
              <label htmlFor="lastName" className="label-font">
                Last Name (as per PAN)*
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="placeholder-font"
                value={lastName}
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group1">
            <div className="indi-form-group">
              <label htmlFor="aadhaarNo" className="label-font">
                Aadhaar*
              </label>
              <input
                type="text"
                id="aadhaarNo"
                name="aadhaarNo"
                className="placeholder-font"
                value={aadhaarNo}
                placeholder="Enter Aadhaar Number"
                onChange={(e) => {
                  if (!validateAadhaarNo(e.target.value.toLocaleUpperCase())) {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        aadhaarNO:
                          "Please enter a valid 12 digit Aadhaar number",
                      };
                    });
                    return setAadhaarNo(e.target.value.toLocaleUpperCase());
                  } else {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        aadhaarNO: null,
                      };
                    });
                    return setAadhaarNo(e.target.value.toLocaleUpperCase());
                  }
                }}
                required
              />
              {errors.aadhaarNO && (
                <span className="error" >{errors.aadhaarNO}</span>
              )}
            </div>
            <div className="indi-form-group">
              <label htmlFor="panNo" className="label-font">
                PAN*
              </label>
              <input
                type="text"
                id="panNo"
                name="panNo"
                className="placeholder-font"
                value={panNo}
                placeholder="Enter PAN"
                // onChange={(e) => setPanNo(e.target.value.toLocaleUpperCase())}
                onChange={(e) => {
                  if (!validatePanNo(e.target.value.toLocaleUpperCase())) {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        pan: "Please enter a valid PAN number in the format ABCDE1234F",
                      };
                    });
                    return setPanNo(e.target.value.toLocaleUpperCase());
                  } else {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        pan: null,
                      };
                    });
                    return setPanNo(e.target.value.toLocaleUpperCase());
                  }
                }}
                required
              />

              {errors.pan && <span className="error" >{errors.pan}</span>}
            </div>
          </div>
          <div className="address">
            <div className="indi-form-group">
              <label htmlFor="mobileNo" className="label-font">
                Mobile Number (registered with your parent company)*
              </label>
              <input
                type="text"
                id="mobileNo"
                name="mobileNo"
                className="placeholder-font"
                value={mobileNo}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setMobileNo(e.target.value);
                  }
                }}
                placeholder="Enter Mobile Number"
                required
              />
            </div>
          </div>
          <div className="address">
            <div className="indi-form-group">
              <label htmlFor="mobileNo" className="label-font">
                Mobile Number (for Communication with Greattr)*
              </label>
              <input
                type="text"
                id="mobileNo"
                name="mobileNo"
                className="placeholder-font"
                value={state?.MobileNumber}
                onChange={(e) => setpersonal_MobileNo(e.target.value)}
                // disabled={true}
                placeholder="Enter your Mobile Number"
              />
            </div>
          </div>
          <div className="address">
            <div
              className="indi-form-group"
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <label htmlFor="mobileNo" className="label-font">
                Landline Number(registered with your parent company)
              </label>

              <div style={{ display: "flex", gap: "8px" }}>
                <select
                  value={landline.stateCode}
                  onChange={handleStateCodeChange}
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">Select State Code</option>
                  {indianStateCodes.map((code) => (
                    <option key={code.value} value={code.value}>
                      {code.label}
                    </option>
                  ))}
                </select>
                <input
                  id="landline-input"
                  type="tel"
                  name="mobileNo"
                  className="placeholder-font"
                  placeholder="Landline Number"
                  value={landline.number}
                  onChange={handleNumberChange}
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    flexGrow: 1,
                  }}
                />
              </div>
              <p style={{ marginTop: '1rem', fontSize: "15px", color: "#666", textAlign: 'center' }}>
                {landline.stateCode !== "" && "Full Landline:"}
                {landline.stateCode !== "" && landline.number
                  ? `${landline.stateCode}-${landline.number}`
                  : ""}
              </p>
            </div>
          </div>
          <div className="indi-form-group">
            <label htmlFor="dob" className="label-font">
              Date of Birth*
            </label>
            <div className="date-dropdowns">
              <select
                name="day"
                value={dob.day}
                className="placeholder-font"
                onChange={handleDateChange}
                style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
                required
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                className="placeholder-font"
                name="month"
                value={dob.month}
                style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
                onChange={handleDateChange}
                required
              >
                <option value="">Month</option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <select
                className="placeholder-font"
                name="year"
                value={dob.year}
                onChange={handleDateChange}
                style={{ marginLeft: 5, marginRight: 5, minWidth: 100 }}
                required
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="address">
            <label htmlFor="address" className="label-font">
              Address*
            </label>
            <input
              type="text"
              id="address"
              name="address"
              className="placeholder-font"
              value={address}
              placeholder="Enter Address"
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          <div className="address">
            <label htmlFor="email" className="label-font">
              Email*
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="placeholder-font"
              value={email}
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="last-row">
            <div className="indi-form-group">
              <label htmlFor="addressState" className="label-font">
                State*
              </label>
              <input
                type="text"
                id="state"
                name="state"
                className="placeholder-font"
                value={addressState}
                placeholder="Select State"
                onChange={(e) => setAddressState(e.target.value)}
                required
              />
            </div>
            <div className="indi-form-group">
              <label htmlFor="city" className="label-font">
                City*
              </label>
              <input
                type="text"
                id="city"
                name="city"
                className="placeholder-font"
                value={city}
                placeholder="Select City"
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
            <div className="indi-form-group">
              <label htmlFor="pinCode" className="label-font">
                Pincode*
              </label>
              <input
                type="text"
                id="pinCode"
                name="pinCode"
                className="placeholder-font"
                value={pinCode}
                placeholder="Enter Pincode"
                onChange={(e) => setPinCode(e.target.value)}
                required
              />
            </div>
          </div>
          {/* <button onClick={() => alert(`${dob.year}-${dob.month}-${dob.day}`)}>dkasnkadsn don</button>
                    <br /><br /> */}
          <button type="submit" style={{ padding: 15 }}>
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const indianStateCodes = [
  { value: "011", label: "Delhi (011)" },
  { value: "022", label: "Mumbai (022)" },
  { value: "033", label: "Kolkata (033)" },
  { value: "044", label: "Chennai (044)" },
  { value: "020", label: "Pune (020)" },
  { value: "079", label: "Ahmedabad (079)" },
  { value: "080", label: "Bengaluru (080)" },
  { value: "040", label: "Hyderabad (040)" },
  { value: "0484", label: "Kochi (0484)" },
  { value: "0361", label: "Guwahati (0361)" },
  { value: "0141", label: "Jaipur (0141)" },
  { value: "0522", label: "Lucknow (0522)" },
  { value: "0612", label: "Patna (0612)" },
  { value: "0172", label: "Chandigarh (0172)" },
  { value: "0674", label: "Bhubaneswar (0674)" },
  { value: "0755", label: "Bhopal (0755)" },
  { value: "0891", label: "Visakhapatnam (0891)" },
  { value: "0422", label: "Coimbatore (0422)" },
  { value: "0832", label: "Panaji (0832)" },
  { value: "0265", label: "Vadodara (0265)" },
  { value: "0135", label: "Dehradun (0135)" },
  { value: "0124", label: "Gurugram (0124)" },
  { value: "0364", label: "Shillong (0364)" },
  { value: "0385", label: "Imphal (0385)" },
  { value: "0381", label: "Agartala (0381)" },
  { value: "0370", label: "Kohima (0370)" },
  { value: "0389", label: "Aizawl (0389)" },
  { value: "0360", label: "Itanagar (0360)" },
  { value: "0194", label: "Srinagar (0194)" },
  { value: "0191", label: "Jammu (0191)" },
  { value: "0177", label: "Shimla (0177)" },
  { value: "0651", label: "Ranchi (0651)" },
  { value: "0771", label: "Raipur (0771)" },
  { value: "0413", label: "Puducherry (0413)" },
  { value: "0744", label: "Kota (0744)" },
  { value: "0542", label: "Varanasi (0542)" },
  { value: "0240", label: "Aurangabad (0240)" },
  { value: "0231", label: "Kolhapur (0231)" },
  { value: "0712", label: "Nagpur (0712)" },
  { value: "0261", label: "Surat (0261)" },
  { value: "0281", label: "Rajkot (0281)" },
  { value: "0836", label: "Hubli (0836)" },
  { value: "0821", label: "Mysuru (0821)" },
  { value: "0824", label: "Mangaluru (0824)" },
  { value: "0462", label: "Tirunelveli (0462)" },
  { value: "0452", label: "Madurai (0452)" },
  { value: "0877", label: "Tirupati (0877)" },
  { value: "0487", label: "Thrissur (0487)" },
  { value: "0497", label: "Kannur (0497)" },
  { value: "0431", label: "Tiruchirappalli (0431)" },
  { value: "0866", label: "Vijayawada (0866)" },
  { value: "0657", label: "Jamshedpur (0657)" },
  { value: "0164", label: "Bathinda (0164)" },
  { value: "0512", label: "Kanpur (0512)" },
  { value: "0532", label: "Prayagraj (Allahabad) (0532)" },
  { value: "0161", label: "Ludhiana (0161)" },
  { value: "0181", label: "Jalandhar (0181)" },
];
export default DistSafetyCheckPersonalDetails;
