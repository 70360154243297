import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Marquee from "react-marquee-slider";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Prodcards.scss";
import Card2 from "./Card2";
import Card3 from "./Car3";

const Section = styled.section`
  background: #fff;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: content-box;
  max-width: 100%;
  text-align: center;
  font-size: 4.569rem;
  color: var(--text-default);
  font-family: var(--heading-h6);

  @media (max-width: 825px) {
  }

  @media (max-width: 1250px) {
  }

  @media (max-width: 450px) {
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2.287rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 1.125rem;
  }
`;

const ContentWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.143rem;
  max-width: 100%;
  @media (max-width: 825px) {
    // padding: 2%;
  }
`;

const Title = styled(motion.div)`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 110%;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 7rem;
  margin-top: 3rem;
  @media (max-width: 825px) {
    font-size: 6.625rem;
    line-height: 6rem;
  }

  @media (max-width: 450px) {
    font-size: 3.75rem;
    line-height: 4.7rem;
  }
`;

const Subtitle = styled(motion.div)`
  align-self: stretch;
  position: relative;
  font-size: 2.431rem;
  line-height: 180%;
  font-weight: 500;
  font-family: var(--text-small);
  margin-bottom: 50;

  @media (max-width: 450px) {
    font-size: 1.125rem;
    line-height: 2.063rem;
  }
`;

const MarqueeImage = styled.img`
  height: 30rem;
  margin: 0 20px;
`;

const titleVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const Prodcards = ({ className }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <Section className={className}>
      <Container>
        <ContentWrapper>
          <Content>
            <div className="cardslassi">
              <div className="cardslassi2">
                <Card2
                  forConsumers="Affordable Essentials, 
On Your Terms"
                  enjoyFlexiblePaymentOptio="We offer flexible Buy Now, Pay Later (BNPL) options, ensuring that families can access critical products like LPG and household appliances without upfront financial pressure."
                  app="/loansuccs.png"
                />

                {/*  */}
                {/*  */}
                {/*  */}

                <Card2
                  forConsumers="Enabling Smart, Affordable Choices"
                  enjoyFlexiblePaymentOptio="With easy access to affordable financing, we help families make necessary purchases that improve their daily lives, without compromising on quality or essentials."
                  app="/cycle.png"
                />
              </div>

              <div className="cardslassi2">
                <Card3
                  forConsumers="Bringing Convenience to Your Doorstep"
                  enjoyFlexiblePaymentOptio="We bring essential products and services to communities across rural and urban India."
                  app="/c1.png"
                />
                <Card3
                  forConsumers="Creating Opportunities for Progress"
                  enjoyFlexiblePaymentOptio="From energy-efficient stoves to solar solutions, we help families make eco-friendly choices."
                  app="/c2.png"
                />
                <Card3
                  forConsumers="A Commitment to Inclusion"
                  enjoyFlexiblePaymentOptio="Greattr is here to serve everyone, especially those who need it most, helping families move forward with dignity and confidence."
                  app="/c3.png"
                />
              </div>
            </div>
          </Content>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

Prodcards.propTypes = {
  className: PropTypes.string,
};

export default Prodcards;
