import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  justify-content: center;

  box-sizing: border-box;
  width: 100vw; // Adjusted to take full viewport width
  text-align: center;
  font-size: 5.713rem;
  color: var(--text-default);
  font-family: var(--heading-h6);
  @media (max-width: 825px) {
  }
  @media (max-width: 1550px) {
  }

  @media (max-width: 500px) {
  }
`;

const InnerDiv = styled(motion.div)`
  border-radius: 18.29px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-left: 1.25rem;
  padding: 3rem;
  padding-top: 6rem;

  background-color: #f5f1fb;
  padding-bottom: 8.212rem;
  box-sizing: border-box;
  gap: 5.5rem;
  max-width: 100%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  @media (max-width: 825px) {
    gap: 2.75rem;
    padding-top: 2.313rem;
    padding-bottom: 5.313rem;
  }
  @media (max-width: 450px) {
    gap: 1.375rem;
  }
`;

const TitleDiv = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
`;

const Title = styled.div`
  position: relative;
  tracking: -0.03em;
  line-height: 6.313rem;
  font-weight: 700;
  display: inline-block;
  max-width: 100%;
  font-size: 7.713rem;
  @media (max-width: 825px) {
    font-size: 2.875rem;
    line-height: 3.75rem;
  }
  @media (max-width: 450px) {
    margin-top: 2rem;

    font-size: 7rem;
    line-height: 8.313rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const StepIconsContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5.287rem;
  max-width: 100%;
  text-align: left;
  font-size: 1.431rem;
  color: var(--gray);
  @media (max-width: 825px) {
    gap: 1.625rem;
  }
`;
const StepCard = styled(motion.div)`
  background: #fff;
  border-radius: 1rem;
  margin: 1rem;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40rem;
  transition: box-shadow 0.3s ease, border 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    border: 2px solid #573a87; // Purple border on hover
    transform: scale(1.05); // Slightly increase the size on hover
  }
`;

const IconWrapper = styled(motion.div)`
  margin-bottom: 1.5rem;
`;

const StepTitle = styled.h3`
  font-size: 2.431rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: var(--text-default);
  text-align: center;
`;

const StepDescription = styled.p`
  font-size: 1.4rem;
  color: var(--gray);
  text-align: center;
  line-height: 1.5;
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};

const Apply = ({ className = "" }) => {
  const steps = [
    {
      icon: "/applyicon.svg",
      title: "Download Greattr App",
      description:
        "Get started by downloading the Greattr app from the App Store or Google Play Store.",
    },
    {
      icon: "/applyicon-1.svg",
      title: "Create Your Account",
      description:
        "Create your account and provide basic information to set up your profile.",
    },
    {
      icon: "/applyicon-2.svg",
      title: "Choose Your Product",
      description:
        "From daily needs to big requirements, choose the type of product you need.",
    },
    {
      icon: "/applyicon-3.svg",
      title: "Apply For Loan",
      description:
        "Once applied, you'll receive confirmation and approval details from our team.",
    },
  ];

  return (
    <Section id="HowToApply" className={className}>
      <InnerDiv
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <TitleDiv
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <Title>How to apply?</Title>
        </TitleDiv>

        <StepIconsContainer
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {steps.map((step, index) => (
            <StepCard
              key={index}
              variants={itemVariants}
              whileHover={{ scale: 1.03 }}
            >
              <IconWrapper
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.6 }}
              >
                <img src={step.icon} alt={step.title} width="64" height="64" />
              </IconWrapper>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepCard>
          ))}
        </StepIconsContainer>
      </InnerDiv>
    </Section>
  );
};

export default Apply;
