import React, { useState } from "react";
import "./DistCreatedSafetyCheck.scss";
import greattrlogo from "../assets/logo.png";
import MerchantLogo from "../assets/MerchantLogo.png";
import OperatorLogo from "../assets/OperatorLogo.png";
import PowerToChoose from "../SvgComponents/PowerToChoose";
import Flexible from "../SvgComponents/Flexible";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  setToken,
  getToken,
  removeToken,
  setRefreshToken,
} from "../utils/auth";
import log12 from "../assets/sidefreamelogin.png";
import { GDSRecords, GESRecords, GES, GDS } from "../../Utilsx/Encrypt";

import axios from "../../components/utils/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { encryptData } from "../utils/encryptionUtils";
const DistCreatedSafetyCheck = () => {
  const [MobileNumber, setMobileNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPVisible, setOTPVisible] = useState(false);
  const [isRequestedOtp, setIsRequestedOtp] = useState(false);
  const [isSelectedMerchant, setIsSelectedMerchant] = useState(false);
  const [isSelectedOperator, setIsSelectedOperator] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const location = useLocation();
  const locationState = location.state;
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const handleOptionSelect = (option) => {
    if (selectedOption === option) {
      setSelectedOption(null); // Deselect if already selected
    } else {
      setSelectedOption(option); // Select the clicked option
    }
  };

  const handleSubmit = () => {
    if (selectedOption === "operator") {
      navigate("/OperatorLogin");
    } else if (selectedOption === "merchant") {
      navigate("/MerchantLogin");
    }
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    if (e.target.value.length < 7) {
      setOTP(e.target.value);
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (MobileNumber === "8779096439" && OTP === "123456x") {
      //   navigate("/api-hub/introduction");
      alert("admin login");
      setToken("xyzsa");
      navigate("/PersonalDetails", {
        state: {
          MobileNumber: "8779096439",
        },
      });
    } else {
      const encryptedData = GESRecords(
        {
          mobile: MobileNumber,
          otpinput: OTP,
        },
        apiEN
      );

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/OperatorOtpLogin`,
          encryptedData
        );

        if (response.status === 200) {
          if (response?.data?.message) {
            // alert(response?.data?.message);
            if (response?.data?.message === "SUCCESS") {
              console.log(response.data);
              setToken(response.data.token);
              var GreattrOperatorId = response.data.GreattrOperatorId;
              localStorage.setItem("MobileNumber", MobileNumber);
              localStorage.setItem("GreattrOperatorId", GreattrOperatorId);

              navigate(response.data.navigate, {
                state: {
                  MobileNumber: MobileNumber,
                  GreattrOperatorId: GreattrOperatorId,
                },
              });
              window.location.reload();

              //     alert("admin login");
            }
          }
        }
        // Handle the response data (e.g., update state, display a message)
        console.log("Response data:", response.data);
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error("Error:", error);
      }
    }
  };

  const toggleOTPVisibility = () => {
    setOTPVisible(!OTPVisible);
  };
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const encryptedData = GESRecords(
      {
        mobile: MobileNumber,
        OTP,
      },
      apiEN
    );

    if (MobileNumber === "8779096439" && OTP === "123456") {
      //   navigate("/api-hub/introduction");
      alert("admin login");
      setIsRequestedOtp(true);
      // setToken("xyzsa");
      // navigate("/PersonalDetails", {
      //     state: {
      //         MobileNumber: "8779096439",
      //     },
      // });
    } else {
      console.log("Production OTP login");
    }
  };

  let handleTest = async () => {
    let EncrytedSubmitData1 = {
      mobile: "d4a4IBKSOHVGbB2jqUIPZA==",
      GreattrDistID: "d1jpZ8bRP7bTCaWqX8jGNg==",
      agencyName: "bPGDZhTGRzA6FvGTuM8iTQ==",
      subEntity: "M1jRqFI33f0emDFm7/YF8w==",
      merchantDistId: "lK2VVWhs7GAC0H5TvWhG+w==",
      parentCompanyName: "9g59uVmon250VakPRakEDg==",
    };

    let EncryptedSubmitData2 = {
      mobile: "d4a4IBKSOHVGbB2jqUIPZA==",
      GreattrDistID: "d1jpZ8bRP7bTCaWqX8jGNg==",
      agencyName: "bPGDZhTGRzA6FvGTuM8iTQ==",
      subEntity: "M1jRqFI33f0emDFm7/YF8w==",
      merchantDistId: "lK2VVWhs7GAC0H5TvWhG+w==",
      parentCompanyName: "9g59uVmon250VakPRakEDg==",
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/CreateMerchantProfileDistributor`,
        EncryptedSubmitData2
      );

      if (response.data.duplicate) {
        alert(response.data.message);
      } else if (response.data.error) {
        alert(response.data.error);
      } else {
        alert(response.data.message);

        navigate("/DistFinalSet", {
          state: {
            ...locationState,
            role: "Dist",
            agencyName: "Neeraj GAS",
            parentCompanyName: "BPCL",
            AadhaarNumber: "299220608158",
            GreattrDealerID: undefined,
            GreattrDistID: "GRBP444555",
            GreattrOperatorId: "FPxzA1CGIJmDCMUPB2rKsA==",
            MobileNumber: "8779341486",
            address: "A-101",
            city: "Mumbai India",
            email: "Neerajbutola8910@gmail.com",
            landline_Number: "0612-27721173",
            parentCompany: "BPCL",
            personal_mobile: "8779341486",
            pincode: "40070",
            state: "Maharashtra",
            GreattrDealerID: response?.data?.GreattrDealerID,
            GreattrDistID: response?.data?.GreattrDistID,
          },
        });
      }
    } catch (error) {}
  };

  let finalFunction =
    isRequestedOtp === true ? handleOTPSubmit : handleFormSubmit;
  return (
    <div className="login-container">
      <div className="left-side">
        <div className="greattr-ad">
          <header className="ad-header">
            <PowerToChoose
              style={{
                width: "80%",
              }}
            />
          </header>
          <div className="ad-content">
            <Flexible
              style={{
                marginTop: 10,
                marginBottom: 20,
                width: 350,
                height: 350,
                alignSelf: "center",
              }}
            />
            <h2 className="ad-flexible">Flexible Tenure</h2>
            <p className="ad-description">
              The power to choose your own loan, now in your hands!
            </p>
          </div>
          <footer className="ad-footer">
            <p className="ad-partner-text">
              Are you interested in partnering with us for a carefully designed
              Buy Now, Pay Later scheme tailored to benefit you and your
              customers?
            </p>
            <div className="ad-buttons">
              <button className="ad-button yes">Yes</button>
              <button className="ad-button no">No</button>
            </div>
          </footer>
        </div>
      </div>
      <div className="right-side">
        <form className="login-form" onSubmit={finalFunction}>
          <div className="api-hub-info">
            <img
              // className="moblogo"
              style={{
                width: "80%",
              }}
              src={greattrlogo}
              alt=""
            />
            <h3 className="form-title">
              Your Account has been created Successfully!
            </h3>
            <p className="form-description">
              We are excited to partner with you. You can now apply for our
              schemes.
            </p>
          </div>
          <button
            type="submit"
            className={`merchant-button ${
              selectedOption === "merchant" ? "selected" : ""
            }`}
            onClick={() => handleOptionSelect("merchant")}
          >
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{ alignSelf: "center" }}>
                <img src={MerchantLogo} alt="MerchantLogo" />
              </div>
              <div style={{ padding: "1rem" }}>
                <p
                  className="button-title-label"
                  style={{ textAlign: "left", margin: 0 }}
                >
                  Safety Checking Onboarding
                </p>
                <p
                  className="button-description-label"
                  style={{ textAlign: "left", margin: 0 }}
                >
                  Empower your business to ensure customer safety. We are
                  excited to partner with you in conducting quick, confidential
                  safety checks to enhance trust and security.
                </p>
              </div>
              <div style={{ alignSelf: "center" }}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  size="2x"
                  className={`icon ${
                    selectedOption === "merchant" ? "selected" : ""
                  }`}
                />
              </div>
            </div>
          </button>

          <button
            type="submit"
            className="form-button"
            onClick={handleSubmit}
            disabled={!selectedOption}
          >
            Process to sign the agreement
          </button>

          {/* <div className="not-registered" style={{ textAlign: "center" }}>
            By signing up to create an account I accept
            <br />
            Greattr’s {""} Terms of Use & {""}
            <a href="/register" className="form-link">
              Privacy Policy.
            </a>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default DistCreatedSafetyCheck;
