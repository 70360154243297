import PropTypes from "prop-types";
import styled from "styled-components";

const CardContainer = styled.div`
  flex: 1;
  border-radius: 22.47px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2.625rem 2.437rem 2.625rem 2.812rem;
  gap: 1.406rem;
  min-width: 16.188rem;
  max-width: 100%;
  text-align: left;
  font-size: 1.756rem;
  color: var(--text-default);
  font-family: var(--font-heading-h6);
  border: 2.8px solid #e8e8e8;

  @media (max-width: 450px) {
    padding: 1.688rem 1.25rem;
  }
`;

const IconContainer = styled.div`
  width: 4.388rem;
  height: 4.388rem;
  border-radius: 140.43px;
  background-color: #eaeaea;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.968rem 0.937rem 0.962rem;
  box-sizing: border-box;
`;

const Icon = styled.img`
  height: 2.456rem;
  width: 2.456rem;
  position: relative;
`;

const ContentContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.7rem;
`;

const Title = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.03em;
  line-height: 120%;
  font-weight: 500;

  @media (max-width: 450px) {
    font-size: 1.375rem;
    line-height: 1.688rem;
  }
`;

const Description = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.406rem;
  line-height: 160%;
  font-weight: 500;
  font-family: var(--font-text-small);

  @media (max-width: 450px) {
    font-size: 1.125rem;
    line-height: 1.813rem;
  }
`;

const Card1 = ({
  className = "",
  riflashlightLine,
  instant,
  loanApproval,
  getQuickAccessToMicroLoan,
}) => {
  return (
    <CardContainer className={className}>
      <IconContainer>
        <Icon loading="lazy" alt="" src={riflashlightLine} />
      </IconContainer>
      <ContentContainer>
        <Title>
          {instant}
          <br />
          {loanApproval}
        </Title>
        <Description>{getQuickAccessToMicroLoan}</Description>
      </ContentContainer>
    </CardContainer>
  );
};

Card1.propTypes = {
  className: PropTypes.string,
  riflashlightLine: PropTypes.string,
  instant: PropTypes.string,
  loanApproval: PropTypes.string,
  getQuickAccessToMicroLoan: PropTypes.string,
};

export default Card1;
