import styled from "styled-components";
import PropTypes from "prop-types";
import { useState } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { motion } from "framer-motion"; // Import motion from framer-motion
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Routes,
} from "react-router-dom";

const GreattrFinalLogo2Icon = styled.img`
  height: 5.488rem;
  width: 17.188rem;
  position: relative;
  align-self: center;
  justify-self: center;
  object-fit: contain;
`;

const NavLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.8rem 0.2rem;
  box-sizing: border-box;
`;

const AnimatedMobileMenu = styled(motion.div)`
  display: none;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  color: #fff;
  padding: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
  overflow-y: auto;

  @media screen and (max-width: 900px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  }
`;

const MobileMenuButtonOpen = styled.button`
  display: none;
  background: none;
  border: none;
  margin-top: 1rem;
  font-size: 2.5rem;
  cursor: pointer;
  color: #3f0261; // Darker color for visibility
  padding: 1.2rem; // Add some padding for better click area
  background-color: #fff; // Background color to stand out
  border-radius: 6px; // Add some border radius for better aesthetics

  z-index: 101;

  @media screen and (max-width: 900px) {
    display: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? "none" : "block")};
  }

  // Hover animation
  &:hover {
    background-color: #3f0261; // Change background color on hover
    color: #fff; // Change text color on hover
    transition: background-color 0.3s, color 0.3s; // Smooth transition for background and text color
  }
`;

const MobileMenuButtonClose = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  z-index: 101;
  position: absolute;
  top: 1rem;
  right: 1rem;

  @media screen and (max-width: 900px) {
    display: ${({ isMobileMenuOpen }) => (isMobileMenuOpen ? "block" : "none")};
  }
`;

const NavItem = styled.div`
  border-radius: 6px;
  background-color: #fff;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  color: #333;

  &:hover {
    background-color: #3f0261;
    color: #fff;
  }
  @media screen and (max-width: 700px) {
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 6px;
  }
`;

const NavCenter = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    justify-content: center;
    width: 100%;
  }
`;

const NavRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  align-items: space-around;
  justify-content: space-evenly;
  @media screen and (max-width: 900px) {
    padding: 0.2rem;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeaderRoot = styled.header`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.7rem 2.187rem;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  width: 100vw;
  @media screen and (max-width: 900px) {
    padding: 0.2rem 1.5rem;
  }
`;

const DesktopMenu = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: space-between;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const Component = styled.button`
  cursor: pointer;
  border: none;
  font-size: 1.7rem;
  font-weight: 700;
  padding: 1rem 1.5rem;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  color: #3f0261;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  @media screen and (max-width: 960px) {
    width: 340px;
    background-color: #3f0261;
    color: #fff;
  }

  &:hover {
    color: #fff;
    background-color: #3f0261;
  }
`;

const TextDiv = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: 600;

  &:hover {
    color: #fff;
  }
`;

const StyledLink = styled(RouterLink)`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-size: 1.7rem;
  font-weight: 700;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:hover {
    color: #fff;
    background-color: #3f0261;
  }
`;

const ExternalStyledLink = styled.a`
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-size: 1.7rem;
  font-weight: 700;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:hover {
    color: #fff;
    background-color: #3f0261;
  }
`;

const Header = ({ className = "" }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Animation variants for the MobileMenu
  const mobileMenuVariants = {
    closed: {
      y: "-100%",
      opacity: 0,
      transition: { duration: 0.3 },
    },
    open: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.3 },
    },
  };

  return (
    <HeaderRoot className={className}>
      <Container>
        <NavLeft>
          <GreattrFinalLogo2Icon
            loading="lazy"
            alt="Greattr-Logo"
            src="/greattr-final-logo-2@2x.png"
          />
        </NavLeft>
        <MobileMenuButtonOpen
          onClick={toggleMenu}
          isMobileMenuOpen={isMenuOpen}
        >
          ☰
        </MobileMenuButtonOpen>
        <MobileMenuButtonClose
          onClick={toggleMenu}
          isMobileMenuOpen={isMenuOpen}
        >
          <FaRegWindowClose />
        </MobileMenuButtonClose>
        <DesktopMenu>
          <NavCenter>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <NavItem>
                <TextDiv href="#">Home</TextDiv>
              </NavItem>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <NavItem>
                <TextDiv href="#features">Features</TextDiv>
              </NavItem>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <NavItem>
                <TextDiv href="#HowToApply">How to Apply?</TextDiv>
              </NavItem>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <NavItem>
                <TextDiv href="#contactDetails">Contact</TextDiv>
              </NavItem>
            </motion.div>
          </NavCenter>
          <NavRight>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Component>
                <StyledLink to="/merchantLogin">
                  Become a Merchant Now
                </StyledLink>
              </Component>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <Component>
                <ExternalStyledLink href="https://play.google.com/store/apps/details?id=com.merchantnative">
                  Download App
                </ExternalStyledLink>
              </Component>
            </motion.div>
          </NavRight>
        </DesktopMenu>
      </Container>
      <AnimatedMobileMenu
        variants={mobileMenuVariants}
        initial="closed"
        animate={isMenuOpen ? "open" : "closed"}
        isOpen={isMenuOpen}
      >
        <NavCenter>
          <NavItem>
            <TextDiv
              href="#"
              onClick={() => {
                toggleMenu();
              }}
            >
              Home
            </TextDiv>
          </NavItem>
          <NavItem>
            <TextDiv
              href="#features"
              onClick={() => {
                toggleMenu();
              }}
            >
              Features
            </TextDiv>
          </NavItem>
          <NavItem>
            <TextDiv
              href="#HowToApply"
              onClick={() => {
                toggleMenu();
              }}
            >
              How to Apply?
            </TextDiv>
          </NavItem>
          <NavItem>
            <TextDiv
              href="#contactDetails"
              onClick={() => {
                toggleMenu();
              }}
            >
              Contact
            </TextDiv>
          </NavItem>
        </NavCenter>
        <NavRight>
          <Component>
            <StyledLink to="/merchantLogin">Become a Merchant Now</StyledLink>
          </Component>

          <Component>
            <ExternalStyledLink href="https://play.google.com/store/apps/details?id=com.merchantnative">
              Download App
            </ExternalStyledLink>
          </Component>
        </NavRight>
      </AnimatedMobileMenu>
    </HeaderRoot>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
