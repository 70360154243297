import LeftContent from "./components/LeftContent";
import "./FinalScreen.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { GDSRecords, GESRecords, GES, GDS } from "../../Utilsx/Encrypt";
import axios from "../../components/utils/axiosConfig";
import { Spinner } from "react-bootstrap";
import { useState } from "react";
const FinalScreen = () => {
  const [consentButton, setConsentButton] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  const [BnplConsent, setBnplConsent] = useState("Yes");
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const backToHome = () => {
    localStorage.clear();
    navigate(`/`);
  };

  const BnplConsentChecker = () => {
    let counter = 0;
    return () => {
      if (BnplConsent === "No" && counter === 0) {
        alert("Kindly provide your preference for the BNPL Scheme");
        counter++;
      } else {
        return true;
      }
    };
  };

  let checkBNPLConsent = BnplConsentChecker();

  const saveConsent = async () => {
    try {
      setisLoading(true);

      if (checkBNPLConsent() !== true) {
        return;
      }

      let encryptedData = GESRecords(
        {
          BNPL_preference: BnplConsent,
          MobileNumber: state.mobile,
        },
        apiEN
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/UpdateMerchantBNPLPreference`,
        encryptedData
      );

      if (response.status === 200) {
        console.log("Response data:", response.data);

        if (
          response.data.message ===
          "Merchant BNPL preference updated successfully"
        ) {
          backToHome();
        } else {
          console.log("Unexpected success message:", response.data.message);
        }
      } else {
        console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error updating BNPL preference:", error);
    } finally {
      setisLoading(false);
    }
  };

  const setConsent = (value) => {
    console.log(value);
    setBnplConsent(value);
  };
  return (
    <div className="final-screen">
      <div className="main">
        <LeftContent />
      </div>
      <div className="right">
        <div className="right-container">
          <div className="right-data">
            <div className="safety-datacontaine">
              <div className="safetycontainer">
                <img
                  className="greattrlogo-icon"
                  loading="lazy"
                  alt=""
                  src="/greattrlogo.svg"
                />
                <div className="safetytext">
                  <b className="your-safety-check" style={{ margin: "10px 0", whiteSpace: "break-spaces" }}>
                    Your safety check account has been created successfully!
                  </b>
                  <div className="your-account-is">
                    Your account is under review. You'll receive an email or a
                    call from our team once approved.
                  </div>
                </div>
              </div>
              <div className="safetytext" style={{ marginTop: 10 }}>
                <b className="your-safety-check" style={{ margin: "10px 0", whiteSpace: "break-spaces" }}>
                  Have you tried our Buy Now, Pay Later offering?
                </b>
                <div className="are-you-interested-container">
                  <span>{`Are you interested in partnering with us for a carefully designed `}</span>
                  Buy Now, Pay Later scheme to benefit you and your customers?
                </div>
                <div className="button-container" style={{ margin: "20px 0" }}>
                  <button
                    className={
                      BnplConsent === "Yes"
                        ? "secondary-button"
                        : "secondary-button1"
                    }
                    onClick={() => {
                      setConsent("Yes");
                      setConsentButton(true);
                    }}
                  >
                    <div className={BnplConsent === "Yes" ? "label" : "label1"}>
                      Yes
                    </div>
                  </button>
                  <button
                    className={
                      BnplConsent === "No"
                        ? "secondary-button"
                        : "secondary-button1"
                    }
                    onClick={() => {
                      setConsent("No");
                      setConsentButton(false);
                    }}
                  >
                    <div className={BnplConsent === "No" ? "label" : "label1"}>
                      No
                    </div>
                  </button>
                </div>
                {consentButton === true ? (
                  <div className="safetytext">
                    <p className="your-safety-check">
                      Thanks for showing interest in our BNPL offerings. Our
                      team will contact you with more information on this
                    </p>
                  </div>
                ) : (
                  <div className="safetytext">
                    <p className="your-safety-check">
                      Kindly consider showing interest in our latest BNPL
                      offerings. With no paper work, flexible repayment options,
                      and no hidden fees.Our BNPL solutions are designed to make
                      your experience better and more convenient.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button className="GoTOHome" onClick={saveConsent} style={{ width: "100%", }}>
            <div className="finalscreen-button" style={{ textAlign: 'center' }}> {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Back To Home"
            )}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalScreen;
