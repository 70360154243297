import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCircleCheck, 
  faCircleExclamation, 
  faCircleXmark,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";

const StepIndicator = ({ steps, onStepClick }) => {


  
  const [selectedStep, setSelectedStep] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleStepClick = (index, status) => {
    if (status !== "Completed") {
      setSelectedStep(index);
      onStepClick(index);
    }
  };

  const getOrdinalSuffix = (num) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = num % 100;
    return suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
  };

  const statusInfo = {
    incomplete: {
      title: "Step Incomplete",
      description: "This step requires your attention. Click on continue to complete this step.",
      icon: faCircleExclamation,
      color: "#ff9800",
      background: "#fff3e0"
    },
    Completed: {
      title: "Step Complete",
      description: "All required actions for this step have been successfully completed.",
      icon: faCircleCheck,
      color: "#4caf50",
      background: "#e8f5e9"
    },
    Error: {
      title: "Step Error",
      description: "There was an issue with this step. Click on continue to redo this step.",
      icon: faCircleXmark,
      color: "#f44336",
      background: "#ffebee"
    }
  };

  const styles = {
    container: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "24px",
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      boxShadow: "0 4px 16px rgba(0, 0, 0, 0.08)",
      width: "100%",
      maxWidth: isMobile ? "100%" : "1000px",
      margin: "auto",
      boxSizing: "border-box",
      overflow: "hidden"
    },
    legendContainer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      marginBottom: "32px",
      gap: "16px",
      padding: "16px",
      backgroundColor: "#f8fafc",
      borderRadius: "8px"
    },
    legendItem: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      padding: "12px 16px",
      borderRadius: "6px",
      transition: "all 0.2s ease",
      cursor: "pointer",
      width: isMobile ? "100%" : "auto",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)"
    },
    legendIcon: {
      marginRight: "12px",
      fontSize: "20px"
    },
    legendText: {
      fontSize: "15px",
      fontWeight: "600",
      color: "#1a202c"
    },
    infoIcon: {
      marginLeft: "8px",
      fontSize: "16px",
      color: "#718096",
      cursor: "pointer"
    },
    tooltip: {
      position: "absolute",
      top: "100%",
      left: "50%",
      transform: "translateX(-50%)",
      marginTop: "8px",
      padding: "16px",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
      width: "280px",
      zIndex: 10,
      opacity: 0,
      visibility: "hidden",
      transition: "all 0.2s ease"
    },
    activeTooltip: {
      opacity: 1,
      visibility: "visible"
    },
    tooltipTitle: {
      fontSize: "16px",
      fontWeight: "600",
      marginBottom: "8px",
      color: "#1a202c"
    },
    tooltipDescription: {
      fontSize: "14px",
      color: "#4a5568",
      lineHeight: "1.5"
    },
    verticalLine: {
      position: "absolute",
      left: "55px",
      top: isMobile ? "290px" : "190px",
      bottom: "80px",
      width: "8px",
      backgroundColor: "#e2e8f0",
      zIndex: 1
    },
    step: {
      display: "flex",
      alignItems: "flex-start",
      marginBottom: "16px",
      cursor: "pointer",
      backgroundColor: "transparent",
      padding: "16px",
      borderRadius: "8px",
      position: "relative",
      width: "100%",
      boxSizing: "border-box",
      transition: "all 0.2s ease",
      border: "2px solid transparent"
    },
    selectedStep: {
      backgroundColor: "#f7fafc",
      borderColor: "#4a5568",
      transform: "scale(1.01)"
    },
    stepIconContainer: {
      marginRight: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "36px",
      height: "36px",
      borderRadius: "50%",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
    },
    stepIcon: {
      fontSize: "24px",
      zIndex: 2
    },
    stepContent: {
      flex: 1,
      fontSize: isMobile ? "15px" : "16px",
      fontWeight: "500",
      color: "#2d3748"
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.legendContainer}>
        {Object.entries(statusInfo).map(([status, info], index) => (
          <div
            key={index}
            style={{
              ...styles.legendItem,
              backgroundColor: info.background
            }}
            onMouseEnter={() => setActiveTooltip(status)}
            onMouseLeave={() => setActiveTooltip(null)}
          >
            <FontAwesomeIcon
              icon={info.icon}
              style={{ ...styles.legendIcon, color: info.color }}
            />
            <span style={styles.legendText}>{info.title}</span>
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={styles.infoIcon}
            />
            <div
              style={{
                ...styles.tooltip,
                ...(activeTooltip === status ? styles.activeTooltip : {})
              }}
            >
              <div style={styles.tooltipTitle}>{info.title}</div>
              <div style={styles.tooltipDescription}>{info.description}</div>
            </div>
          </div>
        ))}
      </div>
      <div style={styles.verticalLine} />
      {steps.map((step, index) => {
       console.log("step",typeof step.status)
       
        const stepStatusInfo = statusInfo[step?.status] || statusInfo.incomplete; // Default to Incomplete if status is undefined

        return (
          <div
            key={index}
            onClick={() => handleStepClick(index, step.status)}
            style={{
              ...styles.step,
              ...(selectedStep === index ? styles.selectedStep : {}),
              backgroundColor: stepStatusInfo?.background || "#f44336", // Fallback background color
            }}
          >
            <div style={styles.stepIconContainer}>
              <FontAwesomeIcon
                icon={stepStatusInfo?.icon||statusInfo.incomplete.icon} // Fallback icon
                style={{
                  ...styles?.stepIcon,
                  color: stepStatusInfo?.color|| statusInfo.incomplete.color // Fallback color
                }}
              />
            </div>
            <div style={styles.stepContent}>
              {`${index + 1}${getOrdinalSuffix(index + 1)} Step: ${step.name}`}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepIndicator;