import React from 'react';
import { FaCheckCircle, FaUsers, FaChartLine, FaLock } from 'react-icons/fa';
import { motion } from 'framer-motion';

const SellingPoints = () => {
  const styles = {
    container: {
      padding: '40px 20px',
      backgroundColor: '#f9f9f9',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      color: '#333',
      marginBottom: '20px',
    },
    pointsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 20px',
    },
    point: {
      margin: '10px 0',
      padding: '20px',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
    iconWrapper: {
      fontSize: '3rem',
      color: '#573a87',
      marginBottom: '15px',
    },
    pointTitle: {
      fontSize: '1.5rem',
      color: '#333',
      marginBottom: '10px',
    },
    pointDescription: {
      fontSize: '1rem',
      color: '#555',
    }
  };

  const [gridStyle, setGridStyle] = React.useState(styles.pointsContainer);

  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setGridStyle({
          ...styles.pointsContainer,
          gridTemplateColumns: 'repeat(4, 1fr)'
        });
      } else if (width >= 768) {
        setGridStyle({
          ...styles.pointsContainer,
          gridTemplateColumns: 'repeat(2, 1fr)'
        });
      } else {
        setGridStyle({
          ...styles.pointsContainer,
          gridTemplateColumns: '1fr'
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sellingPointsData = [
    {
      icon: <FaCheckCircle />,
      title: "Flexible Payment Options",
      description: "Split your payments into manageable installments."
    },
    {
      icon: <FaUsers />,
      title: "No Hidden Fees",
      description: "Designed to make your experience stress-free."
    },
    {
      icon: <FaChartLine />,
      title: "Financial Freedom",
      description: "Empower and plan  your purchases without immediate financial strain."
    },
    {
      icon: <FaLock />,
      title: "Secure & Reliable",
      description: "Your financial data and transactions are safe with us."
    }
  ];
  
  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Why Choose Us</h2>
      <div style={gridStyle}>
        {sellingPointsData.map((point, index) => (
          <motion.div
            key={index}
            style={styles.point}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            whileHover={{ transform: 'translateY(-10px)' }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          >
            <div style={styles.iconWrapper}>{point.icon}</div>
            <h3 style={styles.pointTitle}>{point.title}</h3>
            <p style={styles.pointDescription}>{point.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SellingPoints;