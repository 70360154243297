import React, { useState, useEffect } from "react";
import "./DistSafetyCheckCompanyInfo.scss";
import greattrlogo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GDSRecords, GESRecords } from "../../Utilsx/Encrypt";
import axios from "../../components/utils/axiosConfig";

const DistSafetyCheckCompanyInfo = () => {
  const location = useLocation();

  const [locationState, setLocationState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  const [agencyName, setAgencyName] = useState("");
  const [parentCompanyName, setParentCompanyName] = useState(
    locationState.parentCompany || ""
  );
  const [subEntity, setSubEntity] = useState("");
  const [merchantDistId, setMerchantDistId] = useState("");
  const [greattrDistributorID, setgreattrDistributorID] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const [distdata, setdistdata] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [matchingResults, setMatchingResults] = useState([]);
  const [linkDistMobileNumber, setLinkDistMobileNumber] = useState();

  const navigate = useNavigate();

  console.log(locationState);

  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission
    console.log({
      agencyName,
      subEntity,
      merchantDistId,
      parentCompanyName,
    });

    const submitData = {
      mobile: locationState?.MobileNumber ?? "",
      GreattrDistID: merchantDistId
        ? "GR" + parentCompanyName.slice(0, 2) + merchantDistId
        : "",
      agencyName,
      subEntity,
      merchantDistId,
      parentCompanyName,
      LinkingDistID: greattrDistributorID,
    };
    const EncrytedSubmitData = GESRecords(submitData, apiEN);
    try {
      let route =
        subEntity === "Dealer" || subEntity === "Urjadevi"
          ? "/CreateMerchantProfileDealer"
          : "/CreateMerchantProfileDistributor";
      // alert(route);
      let role =
        subEntity === "Dealer" || subEntity === "Urjadevi" ? "Dealer" : "Dist";
      // alert(role);

      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}${route}`,
        EncrytedSubmitData
      );
      if (response.data.duplicate) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
        navigate("/safetycheck/DistSafetyCheckBusinessDetails", {
          state: {
            ...locationState,
            role: role,
            agencyName,
            parentCompanyName: parentCompanyName,
            GreattrDealerID: response?.data?.GreattrDealerID,
            GreattrDistID: response?.data?.GreattrDistID,
          },
        });
      }
    } catch (error) {
      console.log(
        error.response?.data?.message ||
          "An error occurred while submitting the form."
      );
    }
  };

  const fetchDistdata = async (agencyName, parentCompanyName) => {
    try {
      console.log(
        "fetch for dist id and name triggered-----------------------"
      );

      // setloading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/FetchDistributorDatafromNameForDealer`,
        {
          name: agencyName,
          company: parentCompanyName,
        },
        {
          responseType: "json",
        }
      );

      console.log("Res start-------------");

      const resData = GDSRecords(response.data, apiDE);
      console.log("Response data for distributor details ");
      console.log(resData);
      console.log("Response data for distributor details ");

      if (Object.keys(resData).length > 0) {
        setdistdata(resData);
        setMatchingResults(resData);
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
        setMatchingResults([]);
        setdistdata();
      }

      if (resData.msg === "No details found") {
        console.log("No details found");
      } else {
        console.log("data inserted");
      }
      // setloading(false);
      console.log("Res end-------------");
    } catch (err) {
      console.log("Error Fetching Distributor personal details");
      console.error(err);
      // setloading(false);
    }
  };

  const debounce = (func, waitTime) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), waitTime);
    };
  };

  const debouncedFetchDistdata = debounce(fetchDistdata, 1000);

  useEffect(() => {
    if (agencyName) {
      debouncedFetchDistdata(agencyName, parentCompanyName);
    } else {
      setShowDropdown(false);
    }
  }, [agencyName]);

  const handleSelectResult = (result) => {
    setAgencyName(result.name);
    setgreattrDistributorID(result.GreattrDistID);
    setLinkDistMobileNumber(result.mobile);
    setShowDropdown(false);
  };

  return (
    <div className="safetycheckCompanyInfo-company-info">
      <div className="safetycheckCompanyInfo-company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p style={{ fontSize: "1rem" }}>1 / 6</p>
        <h3 style={{ fontSize: "2rem", whiteSpace: "nowrap" }}>
          Company Information
        </h3>
        <h4 style={{ fontSize: "1rem", marginBottom: "1rem" }}>
          Please enter your company information
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="safetycheckCompanyInfo-form-group">
            <label htmlFor="parentCompanyName">Parent Company Name</label>
            <select
              id="parentCompanyName"
              name="parentCompanyName"
              value={parentCompanyName}
              onChange={(e) => {
                setParentCompanyName(e.target.value);
                setSubEntity("Dist");
              }}
              required
            >
              <option value="" disabled>
                Select Company Name
              </option>
              <option value="BPCL">Bharat Petroleum</option>
              <option value="HPCL">Hindustan Petroleum</option>
              <option value="TOTAL">Total Energy</option>
              <option value="IOCL">Indian Oil</option>

              {/* Add more options as needed */}
            </select>
          </div>
          <div className="safetycheckCompanyInfo-form-group">
            <label htmlFor="subEntity">Sub - Entity*</label>
            <select
              id="subEntity"
              name="subEntity"
              value={subEntity}
              onChange={(e) => setSubEntity(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Sub - Entity
              </option>
              <option value="Dist">Distributor</option>
              {parentCompanyName !== "BPCL" && (
                <option value="Dealer">Dealer</option>
              )}
              {parentCompanyName === "BPCL" && (
                <option value="Urjadevi">Urjadevi</option>
              )}

              {/* Add more options as needed */}
            </select>
          </div>
          {subEntity === "Dist" && parentCompanyName !== "TOTAL" && (
            <div className="safetycheckCompanyInfo-form-group">
              <label htmlFor="merchantDistId">Merchant/Distributor ID</label>
              <input
                type="text"
                id="merchantDistId"
                name="merchantDistId"
                placeholder="Enter Merchant/Distributor ID"
                value={merchantDistId}
                onChange={(e) => setMerchantDistId(e.target.value)}
                required
              />
            </div>
          )}
          <div className="safetycheckCompanyInfo-form-group">
            <label htmlFor="agencyName">Agency Name</label>
            <input
              type="text"
              id="agencyName"
              name="agencyName"
              placeholder="Enter Agency Name"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
              required
            />
            {showDropdown && subEntity !== "Dist" && (
              <div
                className="safetycheckCompanyInfo-AgencyName-dropdown"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "10px",
                  backgroundColor: "#fff",
                  marginTop: "10px",
                }}
              >
                {matchingResults.map((result, index) => (
                  <div
                    key={index}
                    style={{
                      fontSize: 22,
                      padding: "8px",
                      borderBottom: "1px solid #eee",
                      cursor: "pointer",
                    }}
                    className="safetycheckCompanyInfo-AgencyName-dropdown-item"
                    onClick={() => handleSelectResult(result)}
                  >
                    {result.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {subEntity !== "Dist" && (
            <div className="safetycheckCompanyInfo-form-group">
              <label htmlFor="merchantDistId">Greattr Distributor ID</label>
              <input
                type="text"
                id="merchantDistId"
                name="merchantDistId"
                placeholder="Enter Greattr Distributor ID"
                value={greattrDistributorID}
                onChange={(e) => setgreattrDistributorID(e.target.value)}
                required
              />
            </div>
          )}

          {subEntity !== "Dist" && (
            <div className="safetycheckCompanyInfo-form-group">
              <label htmlFor="linkDistMobileNumber">
                Distributor Mobile Number
              </label>
              <input
                type="text"
                id="linkDistMobileNumber"
                name="linkDistMobileNumber"
                placeholder="Enter Distributor Mobile Number"
                value={linkDistMobileNumber}
                onChange={(e) => setLinkDistMobileNumber(e.target.value)}
                required
              />
            </div>
          )}

          <button className="safetycheckCompanyInfoBtn" type="submit">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default DistSafetyCheckCompanyInfo;
