import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const CardContainer = styled(motion.div)`
  font-family: "DM Sans";
  border-radius: 16px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 1rem;
  color: var(--text-default);
  padding-top: 2rem;
  padding-bottom: 0;
  width: 40%;
  margin: 2%;
  // aspect-ratio: 1 / 1; /* Ensures square cards */
  @media (max-width: 768px) {
    aspect-ratio: 1 / 1; /* Ensures square cards */
    margin: 2%;
    border-radius: 0px;
    width: 100%;
    padding: 2rem;
    padding-top: 4rem;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  max-width: 90%;
`;

const Title = styled.div`
  width: 100%;
  color: #000;
  font-size: 4.5rem;
  font-weight: 500;
  // padding-top: 10%;
  font-family: "Inter";
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    // font-size: 1rem;
    // line-height: 1.5rem;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 1.8rem;
  color: #555;
  font-family: "Inter";
  font-weight: 500;
  margin-bottom: 2rem;
  color: rgb(26, 25, 30, 0.5);
  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const AppImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
const AppImage2 = styled.img`
  width: 70%;
  height: auto;
  object-fit: cover;
`;
const Card2 = ({
  className = "",
  forConsumers,
  enjoyFlexiblePaymentOptio,
  suitYourBudget,
  app,
  propPadding,
}) => {
  return (
    <CardContainer
      className={className}
      propPadding={propPadding}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <ContentWrapper>
        <Title>{forConsumers}</Title>
        <Description>
          {enjoyFlexiblePaymentOptio}
          {suitYourBudget}
        </Description>
      </ContentWrapper>
      <ImageWrapper>
        {app === "/loansuccs.png" ? (
          <AppImage2 loading="lazy" alt="Card image" src={app} />
        ) : (
          <AppImage loading="lazy" alt="Card image" src={app} />
        )}
      </ImageWrapper>
    </CardContainer>
  );
};

Card2.propTypes = {
  className: PropTypes.string,
  forConsumers: PropTypes.string,
  enjoyFlexiblePaymentOptio: PropTypes.string,
  suitYourBudget: PropTypes.string,
  app: PropTypes.string,
  propPadding: PropTypes.string,
};

export default Card2;
