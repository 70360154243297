import { useState, useEffect } from "react";

// import greattrQR from "./images/GreattrQRCode.png";
// import whyUseGreattr from "./images/whyUseGreattr.png";

import "./survey.css";
import { useLocation, useNavigate } from "react-router-dom";

import { GDSRecords, GESRecords, GES, GDS } from "../Utilsx/Encrypt";
import axios from "./utils/axiosConfig";
import {
  validateEmail,
  validateMobileNo,
  validatePanNo,
  validateAadhaarNo,
} from "./utils/validations";
import { Spinner } from "react-bootstrap";

function Survey() {
  const location = useLocation();
  console.log("location data ", location);
  const [isLoading, setisLoading] = useState(false);

  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("Savedstate");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });

  useEffect(() => {
    if (location.state !== null) {
      localStorage.setItem("Savedstate", JSON.stringify(location.state));
    }
  }, []);

  console.log("state-----", state);
  const [language, setLanguage] = useState("English");
  const [buyNowPayLater, setBuyNowPayLater] = useState(null);
  const [consent, setConsent] = useState(null);
  const [aadharNumber, setAadharNumber] = useState("");

  const [mobileNumber, setMobileNumber] = useState(
    state?.selectedCustomer?.["Mobile No"] || ""
  );
  const [lpgId, setLpgId] = useState(state?.selectedCustomer?.LPGID || "");
  const [lpgdistId, setLpgdistId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(1);
  const [errors, setErrors] = useState({});
  let selectedCompanyName = "BPCL";
  const [company, setCompany] = useState("BPCL");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_LINK;
  console.log("api url", apiUrl);
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  console.log("apiExN", apiEN);

  useEffect(() => {
    const handleBackButton = () => {
      navigate("/survey", { replace: true });
    };

    const onPopState = () => {
      handleBackButton();
    };

    window.history.pushState(null, "", window.location.pathname);

    window.addEventListener("popstate", onPopState);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, [navigate]);

  const submitHelper = async (e) => {
    e.preventDefault();
    console.log("selected company name ", company);

    let submitData = {
      language: language,
      mobile: mobileNumber,
      Aadhar: aadharNumber,
      lpgID: lpgId,
      state: state,
      PaymentConsent: buyNowPayLater,
      InformedConsent: consent,
      company: company,
      lpg_dist_id: lpgdistId,
      GreattrDistID: "GRBP" + lpgdistId,
    };

    const isValidAadhar = validateAadhar(aadharNumber);
    const isValidMobile = validateMobile(mobileNumber);
    const isValidLpg = validateLpgId(lpgId);
    // const isValidLpgdistId = validateLpgdistId(lpgdistId);

    if (consent === null) {
      alert(
        language === "Hindi"
          ? "कृपया ग्रेटर एप्लिकेशन के संपर्क के लिए सहमति दें"
          : "Please provide Consent to be contacted about Greattr Application"
      );
      return;
    }

    if (buyNowPayLater === null) {
      alert(
        language === "Hindi"
          ? "कृपया ग्रेटर के बारे में आपकी खरीद बाद में भुगतान करने की योजना के बारे में अपनी रुचि दें"
          : "Please provide your interest about Greattr's Buy Now Pay Later Scheme"
      );
      return;
    }

    if (!isValidAadhar) {
      alert(
        language === "Hindi"
          ? "कृपया एक मान्य आधार नंबर दर्ज करें"
          : "Please enter a valid Aadhar Number"
      );

      return;
    }

    if (!isValidMobile) {
      alert(
        language === "Hindi"
          ? "कृपया एक मान्य 10 अंकों का मोबाइल नंबर दर्ज करें"
          : "Please enter a valid 10 digit Mobile Number"
      );
      return;
    }

    if (!isValidLpg) {
      alert(
        language === "Hindi"
          ? "कृपया एक मान्य 17 अंकों का एलपीजी आईडी दर्ज करें"
          : "Please enter a valid 17 digit LPG ID"
      );
      return;
    }

    // if (!isValidLpgdistId) {
    //   alert(
    //     language === "Hindi"
    //       ? "कृपया एक मान्य 6 अंकों का डिस्ट्रीब्यूटर आईडी दर्ज करें"
    //       : "Please enter a valid 6 digit Distributor ID"
    //   );
    //   return;
    // }
    if (
      !language ||
      typeof language === "undefined" ||
      !buyNowPayLater ||
      typeof buyNowPayLater === "undefined" ||
      !consent ||
      typeof consent === "undefined" ||
      !aadharNumber ||
      typeof aadharNumber === "undefined" ||
      !mobileNumber ||
      typeof mobileNumber === "undefined"
    ) {
      alert(
        language === "Hindi"
          ? "कृपया सभी फ़ील्ड्स को सही तरीके से भरें और आगे बढ़ने के लिए"
          : "Please fill in all the fields correctly to proceed"
      );
      return;
    } else {
      try {
        setisLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/Survey/CustomerProfileCreation`,
          { ...submitData },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (
          response.status === 200 &&
          response.data.message !== "Data already exists, KYC Done" &&
          response.data.message !== "Data already exists"
        ) {
          navigate("/surveyotp", {
            state: {
              ...state,
              language: language,
              mobileNumber: mobileNumber,
              Aadhar: aadharNumber,
            },
          });
        } else if (response.data.message === "Data already exists") {
          navigate("/surveyotp", {
            state: {
              ...state,
              language: language,
              mobileNumber: mobileNumber,
              Aadhar: aadharNumber,
            },
          });
        } else {
          alert("Error: " + response.data.message);
        }
      } catch (error) {
        console.error("Error submitting survey:", error);
        if (error.response) {
          // Server responded with a status other than 200 range
          alert(`Error: ${error.response.data.message}`);
        } else if (error.request) {
          // Request was made but no response received
          alert("Error: No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request
          alert("Error: " + error.message);
        }
      } finally {
        setisLoading(false);
      }
    }
  };

  // Validation functions
  const validateAadhar = (number) => {
    const aadharRegex = /^[2-9]{1}[0-9]{11}$/;
    return aadharRegex.test(number);
  };

  const validateLpgdistId = (number) => {
    console.log(number);
    const lpgdistIdRegex = /^[2-9]{1}[0-9]{5}$/;
    console.log(lpgdistIdRegex.test(number));
    return lpgdistIdRegex.test(number);
  };

  const validateMobile = (number) => {
    const mobileRegex = /^[6-9]{1}[0-9]{9}$/;
    return mobileRegex.test(number);
  };

  const validateLpgId = (id) => {
    const lpgRegex = /^[a-zA-Z0-9]{17}$/;
    return lpgRegex.test(id);
  };

  const handleBuyNowPayLaterChange = (value) => {
    setBuyNowPayLater(value);
  };

  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  const handleConsentChange = (value) => {
    setConsent(value);
  };

  const handleAadharNumberChange = (event) => {
    const maxLength = 12;
    const value = event.target.value;

    if (!validateAadhaarNo(event.target.value)) {
      setErrors((prevObj) => {
        return {
          ...prevObj,
          aadhaarNO: "Please enter a valid 12 digit Aadhaar number",
        };
      });
      if (value.length <= maxLength) {
        setAadharNumber(value);
        setaadhaarCharCount(value.length);
      }
    } else {
      setErrors((prevObj) => {
        return {
          ...prevObj,
          aadhaarNO: null,
        };
      });
      if (value.length <= maxLength) {
        setAadharNumber(value);
        setaadhaarCharCount(value.length);
      }
    }
  };

  const handleMobileNumberChange = (event) => {
    const maxLength = 10;
    const value = event.target.value;
    if (value.length == maxLength) {
      fetchCustomer(value);
    }
    if (value.length <= maxLength) {
      setMobileNumber(value);
      setmobCharCount(value.length);
    }
  };

  const [charCount, setCharCount] = useState(0);
  const [distcharCount, setdistCharCount] = useState(0);
  const [AadhaarcharCount, setaadhaarCharCount] = useState(0);
  const [mobcharCount, setmobCharCount] = useState(0);

  let clearPreviousCustomerData = () => {
    setAadharNumber("");
    setLpgId("");
    setLpgdistId("");
  };

  async function fetchCustomer(mobile) {
    try {
      clearPreviousCustomerData();
      const response = await axios({
        url: `${process.env.REACT_APP_API_LINK}/fetchCustomerDataFromExcel`,
        method: "POST",
        data: {
          mobile: mobile,
        },
        responseType: "json",
      });

      if (response?.data?.message !== "Data not found") {
        let datax = response.data;
        console.log(datax.aadhaar);
        datax?.aadhaar ? setAadharNumber(datax.aadhaar) : setAadharNumber("");
        datax?.lpgid ? setLpgId(datax.lpgid) : setLpgId("");
        datax?.distid ? setLpgdistId(datax.distid) : setLpgdistId("");
        console.log("----------------------------------");
        console.log(response.data);
        console.log("----------------------------------");
      }

      // setloading(false);
    } catch (err) {
      console.log("error fetching");
      console.log(err);
      // setloading(false);
    }
  }
  useEffect(() => {
    setaadhaarCharCount(aadharNumber.length);
    setmobCharCount(mobileNumber.length);
    setCharCount(lpgId.length);
    setdistCharCount(lpgdistId.length);
  }, [aadharNumber, mobileNumber, lpgId, lpgdistId]);
  const handleLpgIdChange = (event) => {
    const maxLength = 17;
    const value = event.target.value;

    if (!validateLpgId(value)) {
      if (lpgId.length < maxLength) {
        setLpgId(value);
        setCharCount(value.length);
        setErrors((prevObj) => {
          return {
            ...prevObj,
            lpgId:
              language === "Hindi"
                ? "कृपया एक मान्य 17 अंकों का एलपीजी आईडी दर्ज करें"
                : "Please enter a valid 17 digit LPG ID",
          };
        });
      }
    } else {
      setErrors((prevObj) => {
        return {
          ...prevObj,
          lpgId: null,
        };
      });
      if (lpgId.length < maxLength) {
        setLpgId(value);
        setCharCount(value.length);
      }
    }
  };

  const handleLpgdistIdChange = (event) => {
    const maxLength = 6;
    const value = event.target.value;

    if (value.length <= maxLength) {
      setLpgdistId(value);
      setdistCharCount(value.length);
    }
  };

  const companies = [
    {
      id: 1,
      name: "BPCL",
      image: process.env.PUBLIC_URL + "/img/BPCL.png",
    },
    {
      id: 2,
      name: "IOCL",
      image: process.env.PUBLIC_URL + "/img/IOCL.png",
    },
    { id: 3, name: "HPCL", image: process.env.PUBLIC_URL + "/img/HPCL.png" },
    {
      id: 4,
      name: "TOTAL",
      image: process.env.PUBLIC_URL + "/img/TOTAL.png",
    },
  ];

  const handleSelect = (id) => {
    setSelectedCompany(id);
    selectedCompanyName =
      companies.find((company) => company.id === id)?.name || "None";
    console.log("select compnay ", selectedCompanyName);
    setCompany(selectedCompanyName);
  };

  return (
    <>
      <div className="formContainer">
        <div className="logo">
          <img
            src={process.env.PUBLIC_URL + "/img/svg/Logo.svg"}
            alt="Greattr Logo"
          />
        </div>
        <div className="selectLanguageContainer">
          <div className="selectLanguage">Select Language / भाषा चुने </div>
          <div>
            <button
              className={language === "English" ? "button selected" : "button"}
              onClick={() => handleLanguageChange("English")}
            >
              English
            </button>
            <button
              className={language === "Hindi" ? "button selected" : "button"}
              onClick={() => handleLanguageChange("Hindi")}
            >
              हिंदी
            </button>
          </div>
        </div>
        <div className="instantLoan">
          {language === "English"
            ? "INSTANT ₹600 LOAN FOR YOUR LPG CYLINDER"
            : "आपके एलपीजी सिलेंडर के लिए तुरंत ₹600 का ऋण"}
        </div>

        <div>
          <img
            src={
              process.env.PUBLIC_URL + language == "English"
                ? "/img/svg/whyUseGreattr.svg"
                : "/img/svg/godhelpme3.svg"
            }
            alt="whyUseGreattr"
          />
        </div>
        <hr className="divider" />
        <div className="qrContainer">
          <img
            src={process.env.PUBLIC_URL + "/img/svg/GreattrQRCode.svg"}
            alt="QR Code"
          />
          <div className="qrText">
            {language === "English"
              ? "Scan the QR Code to download the Greattr Customer App"
              : "ग्रेटर ग्राहक ऐप डाउनलोड करने के लिए QR कोड को स्कैन करें"}
          </div>
        </div>
        <hr className="divider" />

        <div>
          <div className="subformHeading">
            {language === "English"
              ? "Enter the following details to continue."
              : "जारी रखने के लिए निम्नलिखित विवरण दर्ज करें।"}
          </div>

          <div className="company-selector">
            <h3 style={{ fontWeight: 600, fontSize: "1.8rem" }}>
              {language === "English" ? "Select Company" : "कंपनी चुने"}
            </h3>
            <div className="companies">
              {companies.map((company) => (
                <div
                  key={company.id}
                  className={`company ${
                    selectedCompany === company.id ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(company.id)}
                >
                  <img src={company.image} alt={company.name} />
                </div>
              ))}
            </div>
          </div>

          <div className="selectOption">
            <label>
              {language === "English"
                ? `Would you be interested in using a "Buy Now, Pay Later" (BNPL) scheme for a gas refill of ₹600 at zero interest and no processing fee, with a weekly EMI of ₹100?`
                : `क्या आप ₹100 की साप्ताहिक ईएमआई के साथ शून्य ब्याज और बिना प्रोसेसिंग शुल्क के ₹600 की गैस रिफिल के लिए "अभी खरीदें, बाद में भुगतान करें" (बीएनपीएल) योजना का उपयोग करने में रुचि रखते हैं?`}
            </label>
            <div className="choiceContainer">
              <button
                className={
                  buyNowPayLater === "Yes" ? "button selected" : "button"
                }
                onClick={() => handleBuyNowPayLaterChange("Yes")}
              >
                {language === "English" ? "Yes" : "हाँ"}
              </button>
              <button
                className={
                  buyNowPayLater === "No" ? "button selected" : "button"
                }
                onClick={() => handleBuyNowPayLaterChange("No")}
              >
                {language === "English" ? "No" : "नहीं"}
              </button>
            </div>
          </div>
          <div className="selectOption">
            <label>
              {language === "English"
                ? `I consent to share my information and download the Greattr Application and be contacted about the BNPL scheme.`
                : `मैं अपनी जानकारी साझा करने और ग्रेटर एप्लिकेशन डाउनलोड करने और बीएनपीएल योजना के बारे में संपर्क करने के लिए सहमति देता हूं।`}
            </label>
            <div className="choiceContainer">
              <button
                className={consent === "Yes" ? "button selected" : "button"}
                onClick={() => handleConsentChange("Yes")}
              >
                {language == "English" ? "Yes" : "हाँ"}
              </button>
              <button
                className={consent === "No" ? "button selected" : "button"}
                onClick={() => handleConsentChange("No")}
              >
                {language === "English" ? "No" : "नहीं"}
              </button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="inputContainer">
              <label htmlFor="aadharNumber">
                {language === "English" ? "Aadhar Number*" : "आधार नंबर*"}
                {`(${12 - AadhaarcharCount} digits remaining)`}
              </label>
              <input
                type="text"
                id="aadharNumber"
                value={aadharNumber}
                className="SurveyInput"
                onChange={handleAadharNumberChange}
                placeholder={
                  language === "English"
                    ? `Enter Aadhar Number`
                    : `आधार नंबर दर्ज करें`
                }
              />
              {errors.aadhaarNO && (
                <span className="error" style={{ color: "red" }}>
                  {errors.aadhaarNO}
                </span>
              )}
            </div>

            <div className="inputContainer">
              <label htmlFor="mobileNumber">
                {language === "English" ? "Mobile Number*" : "मोबाइल नंबर* "}
                {`(${10 - mobcharCount} digits remaining)`}
              </label>
              <input
                type="text"
                id="mobileNumber"
                className="SurveyInput"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
                placeholder={
                  language === "English"
                    ? `Enter Mobile Number`
                    : `मोबाइल नंबर दर्ज करें`
                }
              />
            </div>

            <div className="inputContainer">
              <label htmlFor="lpgId">
                {language === "English" ? `LPG ID*` : `एलपीजी आईडी*`}{" "}
                {`(${17 - charCount} digits remaining)`}
              </label>
              <input
                type="text"
                id="lpgId"
                value={lpgId}
                className="SurveyInput"
                onChange={handleLpgIdChange}
                placeholder={
                  language === "English"
                    ? `Enter LPG ID`
                    : `एलपीजी आईडी दर्ज करें`
                }
              />
              {errors.lpgId && (
                <span className="error" style={{ color: "red" }}>
                  {errors.lpgId}
                </span>
              )}
            </div>
          </div>

          {/* <div className="inputContainer">
            <label htmlFor="distID">
              {language === "English" ? `Distributor ID*` : `वितरक आईडी*`}{" "}
              {`(${distcharCount})`}
            </label>
            <input
              type="text"
              id="distID"
              value={lpgdistId}
              onChange={handleLpgdistIdChange}
              placeholder={
                language === "English"
                  ? `Enter LPG ID`
                  : `एलपीजी आईडी दर्ज करें`
              }
            />
          </div> */}
        </div>
        <button
          className="SurveyproceedBtn"
          onClick={submitHelper}
          style={{
            backgroundColor: "#573A87",
            color: "white",
            padding: "2rem",
            fontWeight: "600",

            width: "100%",
            borderWidth: 1,
            fontSize: "1.5rem",
            borderColor: "white",
            borderRadius: 6,
            overflow: "hidden", // Ensure content does not overflow
          }}
        >
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <>{language === "English" ? `Proceed` : `आगे बढ़ें`}</>
          )}
        </button>
      </div>
    </>
  );
}

export default Survey;
