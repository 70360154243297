import React, { useEffect, useState } from "react";
import "./DistCompanyInfo.scss";
import greattrlogo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GDS, GES } from "../../Utilsx/Encrypt";
import axios from "../../components/utils/axiosConfig";
import { Spinner } from "react-bootstrap";
import TextInputWrapper from "../components/TextInputWrapper";
import useStore from "../../store";

const DistCompanyInfo = () => {
  const location = useLocation();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [locationState, setLocationState] = useState(() => {
    // Check if Zustand store is available and return state from store, otherwise use local state

    if (user && Object.keys(user).length > 0) {
      return user;
    }

    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });

  const [agencyName, setAgencyName] = useState("");
  const [linkDistMobileNumber, setLinkDistMobileNumber] = useState();
  const [parentCompanyName, setParentCompanyName] = useState(
    locationState.parentCompany || ""
  );
  const [subEntity, setSubEntity] = useState("");
  const [merchantDistId, setMerchantDistId] = useState("");
  const [greattrDistributorID, setgreattrDistributorID] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [distdata, setdistdata] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [matchingResults, setMatchingResults] = useState([]);

  const navigate = useNavigate();

  console.log("state data-----------", locationState);

  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission
    console.log({
      agencyName,
      subEntity,
      merchantDistId,
      parentCompanyName,
    });

    const submitData = {
      mobile: locationState?.MobileNumber ?? "",
      GreattrDistID: !locationState?.GreattrDistID
        ? "GR" + parentCompanyName.slice(0, 2) + merchantDistId
        : locationState?.GreattrDistID,
      agencyName,
      subEntity,
      merchantDistId,
      parentCompanyName,
      LinkingDistID: greattrDistributorID,
    };
    const EncrytedSubmitData = GES(submitData, apiEN);
    console.log("GES(submitData, apiEN)");
    console.log(GES(submitData, apiEN));

    try {
      // alert(locationState?.MobileNumber);
      setisLoading(true);
      console.log("GES(submitData, apiEN)");
      console.log(GES(submitData, apiEN));
      let route =
        subEntity === "Dealer" || subEntity === "Urjadevi"
          ? "/CreateMerchantProfileDealer"
          : "/CreateMerchantProfileDistributor";
      // alert(route);
      let role =
        subEntity === "Dealer" || subEntity === "Urjadevi" ? "Dealer" : "Dist";
      // alert(role);

      await axios({
        url: `${process.env.REACT_APP_API_LINK}${route}`,
        method: "POST",
        responseType: "json",
        data: GES(submitData, apiEN),
      }).then((response) => {
        console.log("GG response", response.data);

        if (response.data.duplicate) {
          alert(response.data.message);
        } else {
          console.log(response.data);

          alert(response.data.message);

          const updatedState = {
            ...locationState,
            role: role,
            agencyName,
            parentCompanyName: parentCompanyName,
            GreattrDealerID: response?.data?.GreattrDealerID,
            GreattrDistID: response?.data?.GreattrDistID,
          };
          // Update Zustand user state

          setUser(updatedState);
          navigate("/DistBusinessDetails", {
            state: updatedState,
          });
        }
      });
    } catch (error) {
      console.log(
        error.response?.data?.message ||
          "An error occurred while submitting the form."
      );
    } finally {
      setisLoading(false);
    }
  };

  const fetchDistdata = async (agencyName, parentCompanyName) => {
    try {
      console.log(
        "fetch for dist id and name triggered-----------------------"
      );

      // setloading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/FetchDistributorDatafromNameForDealer`,
        {
          name: agencyName,
          company: parentCompanyName,
        },
        {
          responseType: "json",
        }
      );

      console.log("Res start-------------");

      const resData = GDS(response.data, apiDE);
      console.log("Response data for distributor details ");
      console.log(resData);
      console.log("Response data for distributor details ");

      if (Object.keys(resData).length > 0) {
        setdistdata(resData);
        setMatchingResults(resData);
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
        setMatchingResults([]);
        setdistdata();
      }

      if (resData.msg === "No details found") {
        console.log("No details found");
      } else {
        console.log("data inserted");
      }
      // setloading(false);
      console.log("Res end-------------");
    } catch (err) {
      console.log("Error Fetching Distributor personal details");
      console.error(err);
      // setloading(false);
    }
  };

  const debounce = (func, waitTime) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), waitTime);
    };
  };

  const debouncedFetchDistdata = debounce(fetchDistdata, 5000);

  useEffect(() => {
    if (agencyName) {
      debouncedFetchDistdata(agencyName, parentCompanyName);
    } else {
      setShowDropdown(false);
    }
  }, [agencyName]);

  const handleSelectResult = (result) => {
    setAgencyName(result.name);
    setgreattrDistributorID(result.GreattrDistID);
    setLinkDistMobileNumber(result.mobile);
    setShowDropdown(false);
  };

  return (
    <div className="DistCompanyInfo-company-info">
      <div className="DistCompanyInfo-company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p>1 / 6</p>
        <h3 style={{ fontSize: "3.5rem",fontWeight:"bold" }}>Company Information</h3>
        <h5 style={{ fontSize: "1.8rem",fontWeight:"bold",marginBottom:"2rem" }}>Please enter your company information</h5>
        <form onSubmit={handleSubmit}>
          <div className="DistCompanyInfo-form-group">
            <label htmlFor="parentCompanyName">Parent Company Name</label>
            <select
              id="parentCompanyName"
              name="parentCompanyName"
              value={parentCompanyName}
              onChange={(e) => {
                setParentCompanyName(e.target.value);
                setSubEntity("Dist");
              }}
              required
            >
              <option value="" disabled>
                Select Company Name
              </option>
              <option value="BPCL">Bharat Petroleum</option>
              <option value="HPCL">Hindustan Petroleum</option>
              <option value="TOTAL">Total Energy</option>
              <option value="IOCL">Indian Oil</option>

              {/* Add more options as needed */}
            </select>
          </div>
          <div className="DistCompanyInfo-form-group">
            <label htmlFor="subEntity">Sub - Entity*</label>
            <select
              id="subEntity"
              name="subEntity"
              value={subEntity}
              onChange={(e) => setSubEntity(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Sub - Entity
              </option>
              <option value="Dist">Distributor</option>
              {parentCompanyName !== "BPCL" && (
                <option value="Dealer">Dealer</option>
              )}
              {parentCompanyName === "BPCL" && (
                <option value="Urjadevi">Urjadevi</option>
              )}

              {/* Add more options as needed */}
            </select>
          </div>
          {subEntity === "Dist" && parentCompanyName !== "TOTAL" && (
            <div className="DistCompanyInfo-form-group">
              <label htmlFor="merchantDistId">Merchant/Distributor ID</label>
              <TextInputWrapper
                type="text"
                id="merchantDistId"
                name="merchantDistId"
                placeholder="Enter Merchant/Distributor ID"
                value={merchantDistId}
                onChange={(e) => setMerchantDistId(e)}
                required
              />
            </div>
          )}
          <div className="DistCompanyInfo-form-group">
            <label htmlFor="agencyName">Agency Name</label>
            <TextInputWrapper
              type="text"
              id="agencyName"
              name="agencyName"
              placeholder="Enter Agency Name"
              value={agencyName}
              onChange={(e) => setAgencyName(e)}
              required
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
            />
            {showDropdown && subEntity !== "Dist" && (
              <div
                className="DistCompanyInfo-AgencyName-dropdown"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: "10px",
                  backgroundColor: "#fff",
                  marginTop: "10px",
                }}
              >
                {matchingResults.map((result, index) => (
                  <div
                    key={index}
                    style={{
                      fontSize: 22,
                      padding: "8px",
                      borderBottom: "1px solid #eee",
                      cursor: "pointer",
                    }}
                    className="DistCompanyInfo-AgencyName-dropdown-item"
                    onClick={() => handleSelectResult(result)}
                  >
                    {result.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          {subEntity !== "Dist" && (
            <div className="DistCompanyInfo-form-group">
              <label htmlFor="merchantDistId">Greattr Distributor ID</label>
              <TextInputWrapper
                type="text"
                id="merchantDistId"
                name="merchantDistId"
                placeholder="Enter Greattr Distributor ID"
                value={greattrDistributorID}
                onChange={(e) => setgreattrDistributorID(e)}
                required
              />
            </div>
          )}

          {subEntity !== "Dist" && (
            <div className="DistCompanyInfo-form-group">
              <label htmlFor="linkDistMobileNumber">
                Distributor Mobile Number
              </label>
              <TextInputWrapper
                type="text"
                id="linkDistMobileNumber"
                name="linkDistMobileNumber"
                placeholder="Enter Distributor Mobile Number"
                value={linkDistMobileNumber}
                onChange={(e) => setLinkDistMobileNumber(e)}
                required
              />
            </div>
          )}

          <button className="DistCompanyInfoBtn" type="submit">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <div className="Dist-pd-label-btn-font">
              Continue
             </div>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DistCompanyInfo;
