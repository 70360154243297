import LeftContent from "./components/LeftContent";
import "./FinalScreenMerchant.scss";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessComponent from "../components/SvgComponents/Success";
import { GDSRecords, GESRecords, GES, GDS } from "../Utilsx/Encrypt";
import axios from "./utils/axiosConfig";

import { useState } from "react";
import { Spinner } from "react-bootstrap";
import SellingPoints from "./SellingPoints";
const FinalScreenMechant = () => {
  const [consentButton, setConsentButton] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });
  const [BnplConsent, setBnplConsent] = useState("Yes");
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;

  const backToHome = () => {
    localStorage.clear();
    navigate(`/`);
  };

  const BnplConsentChecker = () => {
    let counter = 0;
    return () => {
      if (BnplConsent === "No" && counter === 0) {
        alert("Kindly provide your preference for the BNPL Scheme");
        counter++;
      } else {
        return true;
      }
    };
  };

  let checkBNPLConsent = BnplConsentChecker();

  const saveConsent = async () => {
    try {
      if (checkBNPLConsent() !== true) {
        return;
      }
      setisLoading(true);

      let encryptedData = GESRecords(
        {
          BNPL_preference: BnplConsent,
          MobileNumber: state.mobile,
        },
        apiEN
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/UpdateMerchantBNPLPreference`,
        encryptedData
      );

      if (response.status === 200) {
        console.log("Response data:", response.data);

        if (
          response.data.message ===
          "Merchant BNPL preference updated successfully"
        ) {
          backToHome();
        } else {
          console.log("Unexpected success message:", response.data.message);
        }
      } else {
        console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error updating BNPL preference:", error);
    } finally {
      isLoading(false);
    }
  };

  const setConsent = (value) => {
    console.log(value);
    setBnplConsent(value);
  };
  return (
    <div className="final-screen">
      <div className="main">
        <LeftContent />
      </div>
      <div className="right">
        <div className="right-container">
          <div className="right-data">
            <div className="safety-data">
              <div className="safetycontainer">
                <img
                  className="Greattr_SzIcon"
                  loading="lazy"
                  alt=""
                  src="/greattrlogo.svg"
                />
                <div className="safetytext">
                  <b className="your-safety-check">
                    Congratulations! Your account has been created successfully.
                  </b>
                  <b className="your-safety-check">While we are reviewing your details, you can start using the Greattr Merchant App.</b>
                </div>
              </div>
              <div className="Account-createdContainer">
                <div className="Account-created">
                  <div className="SuccessComponent">
                    <SuccessComponent />
                  </div>
                  
                </div>
               
                {/* <div className="button-container">
                  <button
                    className={
                      BnplConsent === "Yes"
                        ? "secondary-button"
                        : "secondary-button1"
                    }
                    onClick={() => {
                      setConsent("Yes");
                      setConsentButton(true);
                    }}
                  >
                    <div className={BnplConsent === "Yes" ? "label" : "label1"}>
                      Yes
                    </div>
                  </button>
                  <button
                    className={
                      BnplConsent === "No"
                        ? "secondary-button"
                        : "secondary-button1"
                    }
                    onClick={() => {
                      setConsent("No");
                      setConsentButton(false);
                    }}
                  >
                    <div className={BnplConsent === "No" ? "label" : "label1"}>
                      No
                    </div>
                  </button>
                </div>
                {consentButton === true ? (
                  <span className="error">
                    Thanks for showing interest in our BNPL offerings.
                    <br />
                    Our team will contact you with more information on this
                  </span>
                ) : (
                  <span className="error">
                  </span>
                )} */}
              </div>
            </div>
          </div>
          <SellingPoints />
          <div className="btnContainer">
            <button className="esignbutton" onClick={saveConsent}>
              <div className="finalscreen-button">
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Back To Home"
                )}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalScreenMechant;
