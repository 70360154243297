import * as React from "react";
const EnterAadharAnimation = (props) => (
  <svg
    className="animated"
    id="freepik_stories-id-card"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"

    {...props}
  >
    <style>
      {
        "svg#freepik_stories-id-card:not(.animated) .animable {opacity: 0;}svg#freepik_stories-id-card.animated #freepik--background-complete--inject-78 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedRight;animation-delay: 0s;}svg#freepik_stories-id-card.animated #freepik--Shadow--inject-78 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideRight;animation-delay: 0s;}svg#freepik_stories-id-card.animated #freepik--Character--inject-78 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;animation-delay: 0s;}svg#freepik_stories-id-card.animated #freepik--ID--inject-78 {animation: 1.6s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideDown;animation-delay: 0s;}            @keyframes lightSpeedRight {              from {                transform: translate3d(50%, 0, 0) skewX(-20deg);                opacity: 0;              }              60% {                transform: skewX(10deg);                opacity: 1;              }              80% {                transform: skewX(-2deg);              }              to {                opacity: 1;                transform: translate3d(0, 0, 0);              }            }                    @keyframes slideRight {                0% {                    opacity: 0;                    transform: translateX(30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes fadeIn {                0% {                    opacity: 0;                }                100% {                    opacity: 1;                }            }                    @keyframes slideDown {                0% {                    opacity: 0;                    transform: translateY(-30px);                }                100% {                    opacity: 1;                    transform: translateY(0);                }            }        "
      }
    </style>
    <g
      id="freepik--background-complete--inject-78"
      className="animable"
      style={{
        transformOrigin: "250px 228.23px",
      }}
    >
      <rect
        y={382.4}
        width={500}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "250px 382.525px",
        }}
        id="eldi7dbyaq9af"
        className="animable"
      />
      <rect
        x={416.78}
        y={398.49}
        width={33.12}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "433.34px 398.615px",
        }}
        id="elb9u5uwgxyy"
        className="animable"
      />
      <rect
        x={322.53}
        y={401.21}
        width={8.69}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "326.875px 401.335px",
        }}
        id="el5lwzkcr5m4m"
        className="animable"
      />
      <rect
        x={396.59}
        y={389.21}
        width={19.19}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "406.185px 389.335px",
        }}
        id="elmuzqcwbke3"
        className="animable"
      />
      <rect
        x={52.46}
        y={390.89}
        width={43.19}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "74.055px 391.015px",
        }}
        id="ellytw75wcehl"
        className="animable"
      />
      <rect
        x={104.56}
        y={390.89}
        width={6.33}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "107.725px 391.015px",
        }}
        id="elh8n4nwmmksw"
        className="animable"
      />
      <rect
        x={131.47}
        y={395.11}
        width={93.68}
        height={0.25}
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "178.31px 395.235px",
        }}
        id="elpybplpz1q6"
        className="animable"
      />
      <path
        d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "140.46px 196.4px",
        }}
        id="el315fbezuo0n"
        className="animable"
      />
      <path
        d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "356.75px 196.4px",
        }}
        id="el37l33ns7xcf"
        className="animable"
      />
      <g id="elbd2ktepf1pd">
        <rect
          x={277.91}
          y={83.48}
          width={164.82}
          height={147.6}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "360.32px 157.28px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elkhdk5pu9pyk"
        />
      </g>
      <g id="elyr7jgt2hk5">
        <rect
          x={270.97}
          y={83.48}
          width={168.52}
          height={147.6}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "355.23px 157.28px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="el37xna8ns6st"
        />
      </g>
      <g id="eloww4138zmgo">
        <rect
          x={277.91}
          y={231.07}
          width={164.82}
          height={5}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "360.32px 233.57px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elob11w0brj5"
        />
      </g>
      <g id="el157oaxfasc8">
        <rect
          x={259.41}
          y={231.07}
          width={168.52}
          height={5}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "343.67px 233.57px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="el037k1czon3wp"
        />
      </g>
      <g id="elef5o8pn6x3e">
        <rect
          x={291.15}
          y={82.73}
          width={128.16}
          height={149.09}
          style={{
            fill: "rgb(250, 250, 250)",
            transformOrigin: "355.23px 157.275px",
            transform: "rotate(90deg)",
          }}
          className="animable"
          id="elpokswhvvzk"
        />
      </g>
      <polygon
        points="399.82 221.36 342.79 93.19 284.43 93.19 341.46 221.36 399.82 221.36"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "342.125px 157.275px",
        }}
        id="elfbjw7zpcmvn"
        className="animable"
      />
      <path
        d="M425.28,199.94a.69.69,0,0,0,.69-.69V99.52a.69.69,0,1,0-1.38,0v99.73A.69.69,0,0,0,425.28,199.94Z"
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "425.28px 149.385px",
        }}
        id="elic2i5cs8ver"
        className="animable"
      />
      <polygon
        points="319.52 221.36 262.49 93.19 242.3 93.19 299.33 221.36 319.52 221.36"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "280.91px 157.275px",
        }}
        id="elzq1t2hijgb"
        className="animable"
      />
      <g id="elov2a3ktjn6">
        <rect
          x={217.22}
          y={156.66}
          width={128.16}
          height={1.23}
          style={{
            fill: "rgb(230, 230, 230)",
            transformOrigin: "281.3px 157.275px",
            transform: "rotate(90deg)",
          }}
          className="animable"
          id="elztk8uher60r"
        />
      </g>
      <g id="el0blpf4jglua">
        <g
          style={{
            opacity: 0.5,
            transformOrigin: "351.53px 141.26px",
          }}
          className="animable"
          id="elc7dvvx3o3k6"
        >
          <polygon
            points="268.67 106.54 433.5 106.54 434.39 96.97 269.57 96.97 268.67 106.54"
            style={{
              fill: "rgb(230, 230, 230)",
              transformOrigin: "351.53px 101.755px",
            }}
            id="eld6j17vtwmi"
            className="animable"
          />
          <polygon
            points="268.67 122.34 433.5 122.34 434.39 112.77 269.57 112.77 268.67 122.34"
            style={{
              fill: "rgb(230, 230, 230)",
              transformOrigin: "351.53px 117.555px",
            }}
            id="elcm7ixnzx7g5"
            className="animable"
          />
          <polygon
            points="268.67 138.14 433.5 138.14 434.39 128.57 269.57 128.57 268.67 138.14"
            style={{
              fill: "rgb(230, 230, 230)",
              transformOrigin: "351.53px 133.355px",
            }}
            id="elp4fzzt9tou"
            className="animable"
          />
          <polygon
            points="268.67 153.94 433.5 153.94 434.39 144.37 269.57 144.37 268.67 153.94"
            style={{
              fill: "rgb(230, 230, 230)",
              transformOrigin: "351.53px 149.155px",
            }}
            id="eleou7isbdzuj"
            className="animable"
          />
          <polygon
            points="268.67 169.75 433.5 169.75 434.39 160.18 269.57 160.18 268.67 169.75"
            style={{
              fill: "rgb(230, 230, 230)",
              transformOrigin: "351.53px 164.965px",
            }}
            id="eldda4t29uehg"
            className="animable"
          />
          <polygon
            points="268.67 185.55 433.5 185.55 434.39 175.98 269.57 175.98 268.67 185.55"
            style={{
              fill: "rgb(230, 230, 230)",
              transformOrigin: "351.53px 180.765px",
            }}
            id="elh4276bk8b04"
            className="animable"
          />
        </g>
      </g>
      <g id="eldptiopez3ka">
        <rect
          x={77.87}
          y={83.48}
          width={131.34}
          height={298.92}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "143.54px 232.94px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elvyf1ggig11"
        />
      </g>
      <g id="eldlpvj2vopp">
        <rect
          x={77.87}
          y={83.48}
          width={128.75}
          height={298.92}
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "142.245px 232.94px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elijm34131g1"
        />
      </g>
      <g id="elg0v1axryzal">
        <rect
          x={-3.83}
          y={178.74}
          width={292.14}
          height={115.19}
          style={{
            fill: "rgb(235, 235, 235)",
            transformOrigin: "142.24px 236.335px",
            transform: "rotate(90deg)",
          }}
          className="animable"
          id="eldktkq36g7a9"
        />
      </g>
      <path
        d="M115.91,224c-6.53-1.8-13.06,4-19.59,2.2a4.1,4.1,0,0,1-2.51-3.62h0c0-1.38,1.13-1.78,2.51-1.41,6.53,1.8,13.06-4,19.59-2.2a4.08,4.08,0,0,1,2.52,3.61h0C118.43,224,117.29,224.39,115.91,224Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "106.12px 222.585px",
        }}
        id="el0hsmmjxltolp"
        className="animable"
      />
      <g id="eloa95anodqz">
        <rect
          x={-60.54}
          y={235.45}
          width={292.14}
          height={1.76}
          style={{
            fill: "rgb(224, 224, 224)",
            transformOrigin: "85.53px 236.33px",
            transform: "rotate(90deg)",
          }}
          className="animable"
          id="el8w7amdgxcij"
        />
      </g>
      <g id="eljlpse6n4u1q">
        <rect
          x={415.5}
          y={273.64}
          width={23.56}
          height={102.16}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "427.28px 324.72px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elha3fype6u88"
        />
      </g>
      <g id="el5sv5ps0i20r">
        <rect
          x={318.36}
          y={375.8}
          width={116.17}
          height={6.47}
          style={{
            fill: "rgb(240, 240, 240)",
            transformOrigin: "376.445px 379.035px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elikkmtnmrjpa"
        />
      </g>
      <rect
        x={313.84}
        y={273.64}
        width={101.66}
        height={102.16}
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "364.67px 324.72px",
        }}
        id="elx905ctjpob"
        className="animable"
      />
      <rect
        x={323.1}
        y={286.22}
        width={83.13}
        height={33.5}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "364.665px 302.97px",
        }}
        id="elfi2h546eitw"
        className="animable"
      />
      <rect
        x={323.1}
        y={329.72}
        width={83.13}
        height={33.5}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "364.665px 346.47px",
        }}
        id="elp7iwgdbp94i"
        className="animable"
      />
      <g id="elg7fzru1w5ks">
        <rect
          x={353.04}
          y={284.45}
          width={23.26}
          height={4.59}
          rx={2.3}
          style={{
            fill: "rgb(245, 245, 245)",
            transformOrigin: "364.67px 286.745px",
            transform: "rotate(180deg)",
          }}
          className="animable"
          id="elq0g8w49ey8"
        />
      </g>
      <path
        d="M355.33,332.55H374a2.31,2.31,0,0,0,2.3-2.3h0A2.31,2.31,0,0,0,374,328H355.33a2.3,2.3,0,0,0-2.29,2.3h0A2.3,2.3,0,0,0,355.33,332.55Z"
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "364.67px 330.275px",
        }}
        id="elyk4otlzevd"
        className="animable"
      />
      <path
        d="M393.59,271.63h0a1.75,1.75,0,0,1-1.75-1.74V216.81a1.76,1.76,0,0,1,1.75-1.75h0a1.76,1.76,0,0,1,1.74,1.75v53.08A1.75,1.75,0,0,1,393.59,271.63Z"
        style={{
          fill: "rgb(235, 235, 235)",
          transformOrigin: "393.585px 243.345px",
        }}
        id="elgq6y12xfw4"
        className="animable"
      />
      <rect
        x={377.24}
        y={269.57}
        width={32.69}
        height={4.12}
        style={{
          fill: "rgb(240, 240, 240)",
          transformOrigin: "393.585px 271.63px",
        }}
        id="elbc5vzmld1bv"
        className="animable"
      />
      <path
        d="M402.64,246.85h0a.87.87,0,0,1-.87-.87V219.44a.88.88,0,0,1,.87-.88h0a.88.88,0,0,1,.87.88V246A.87.87,0,0,1,402.64,246.85Z"
        style={{
          fill: "rgb(224, 224, 224)",
          transformOrigin: "402.64px 232.705px",
        }}
        id="elpxn5202qvsr"
        className="animable"
      />
      <path
        d="M368.17,228.62s.65-19.52,25.42-19.52S419,228.62,419,228.62Z"
        style={{
          fill: "rgb(230, 230, 230)",
          transformOrigin: "393.585px 218.86px",
        }}
        id="elbdrbwewq1e4"
        className="animable"
      />
    </g>
    <g
      id="freepik--Shadow--inject-78"
      className="animable"
      style={{
        transformOrigin: "250px 416.24px",
      }}
    >
      <ellipse
        id="freepik--path--inject-78"
        cx={250}
        cy={416.24}
        rx={193.89}
        ry={11.32}
        style={{
          fill: "rgb(245, 245, 245)",
          transformOrigin: "250px 416.24px",
        }}
        className="animable"
      />
    </g>
    <g
      id="freepik--Character--inject-78"
      className="animable"
      style={{
        transformOrigin: "254.277px 277.365px",
      }}
    >
      <path
        d="M233.66,144.06c.05-2.12,2-4.89,9.86-5.39s10.78-.14,14,6c3.5,6.61,7.5,14,4.89,20.77s-10.84,10.76-16.73,14.34-14.66,15.61-14.66,15.61S214.67,150,233.66,144.06Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "243.863px 166.951px",
        }}
        id="elabmzczr8df"
        className="animable"
      />
      <path
        d="M237.25,234.63s11.91,61.14,16.14,86c4.39,25.85,8.09,81.47,8.09,81.47h6.62s8.43-48,0-83.65c.57-28.46-5-87.65-5-87.65Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "254.548px 316.45px",
        }}
        id="el8jgzvb6z6zl"
        className="animable"
      />
      <path
        d="M245.93,233.34s26.12,63.94,35.22,87.45c12.76,17.1,44.83,53.37,46.59,54.1l2.32-5.23s-19.6-38.33-35.4-57.27c-4.2-28.15-24.21-82.68-24.21-82.68Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "287.995px 302.3px",
        }}
        id="eldk8ivwwis1d"
        className="animable"
      />
      <path
        d="M233.63,194.86c-1.88,2.2-3.81,4.3-5.74,6.44s-3.92,4.2-5.91,6.28-4,4.15-6.08,6.16-4.17,4-6.36,6l.33-.35a7.32,7.32,0,0,1-1.62,1.39,9.14,9.14,0,0,1-1.56.75,11.2,11.2,0,0,1-2.7.61,23.31,23.31,0,0,1-4.57,0,54.79,54.79,0,0,1-8.12-1.4c-2.6-.64-5.14-1.39-7.66-2.2s-5-1.71-7.43-2.75a2.1,2.1,0,0,1-1.12-2.75,2.08,2.08,0,0,1,2.36-1.24h0c2.47.52,5,1.05,7.5,1.5s5,.89,7.49,1.2a57.57,57.57,0,0,0,7.26.57,16.32,16.32,0,0,0,3.19-.25,5.38,5.38,0,0,0,1.06-.31.44.44,0,0,0,.17-.11s-.07,0-.16.12l.33-.35c2-2,3.9-4,5.82-6.05l5.74-6.25,11.4-12.61a4.22,4.22,0,0,1,6.35,5.57Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "204.787px 205.037px",
        }}
        id="eltn3d40tvh3"
        className="animable"
      />
      <path
        d="M179.13,212.37,175,205.14,172.1,213s3.51,4,6.85,3.21Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "175.615px 210.727px",
        }}
        id="elt0443wz9se"
        className="animable"
      />
      <path
        d="M169.76,205.35l-1,3.37a2,2,0,0,0,.64,2.12l2.71,2.2,2.86-7.9-2.54-1A2,2,0,0,0,169.76,205.35Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "171.822px 208.512px",
        }}
        id="el0y37ne2xkqzs"
        className="animable"
      />
      <path
        d="M233.71,184.77c-5.88-1-8.48,3.1-11.29,6.19.67,5.46,10.3,16.66,10.3,16.66S244.4,186.51,233.71,184.77Z"
        style={{
          fill: "#573A87",
          transformOrigin: "230.319px 196.118px",
        }}
        id="elwangpshmbiq"
        className="animable"
      />
      <path
        d="M225.89,191.47c.75,5.95,3.59,22.65,11.55,47.65L272.15,234c-2.4-12.58-5.71-24.47-4.18-49.52a5.06,5.06,0,0,0-5.07-5.37c-2.35,0-5.25.1-8.27.4a108,108,0,0,0-15.08,2.23c-3.2.78-6.59,1.82-9.17,2.66A6.6,6.6,0,0,0,225.89,191.47Z"
        style={{
          fill: "#573A87",
          transformOrigin: "248.996px 209.115px",
        }}
        id="elogsur5hx05b"
        className="animable"
      />
      <path
        d="M239.55,181.72l15.08-2.23s-.69,5.79-10.19,13.4C237.22,186.19,239.55,181.72,239.55,181.72Z"
        style={{
          fill: "rgb(255, 255, 255)",
          transformOrigin: "246.911px 186.19px",
        }}
        id="elqlb84dhgr2j"
        className="animable"
      />
      <path
        d="M250.63,162.73c-.24,5,.13,14,4,16.76a29.47,29.47,0,0,1-9.73,9.68,8.73,8.73,0,0,1-5.35-7.45c5.22-2.09,4.49-6.08,3-9.71Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "247.09px 175.95px",
        }}
        id="el3v73oea8aom"
        className="animable"
      />
      <g id="elhniqxi1sj48">
        <path
          d="M247.33,166.52,242.54,172a15.32,15.32,0,0,1,.86,2.61c2-.6,4.53-3.29,4.43-5.49A7.27,7.27,0,0,0,247.33,166.52Z"
          style={{
            opacity: 0.2,
            transformOrigin: "245.186px 170.565px",
          }}
          className="animable"
          id="el9kf759diebo"
        />
      </g>
      <path
        d="M254.43,153.08c-.74,8.17-.88,11.64-5.13,15.72-6.4,6.15-16.27,3.46-18-4.77-1.55-7.4-.09-19.52,8-22.55A11.19,11.19,0,0,1,254.43,153.08Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "242.621px 156.452px",
        }}
        id="eljvtm4ei31i"
        className="animable"
      />
      <path
        d="M248.16,146.72c-.72,4.89,2.17,10.88,4.67,10.94s4.38-9.38,4.38-9.38Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "252.629px 152.19px",
        }}
        id="el5klfdtozv8r"
        className="animable"
      />
      <path
        d="M234.27,144.22c5.33,3.78,21.17,9.45,21.5,4.56s-6.28-8.84-11.17-8.84S235.77,141.89,234.27,144.22Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "245.026px 145.309px",
        }}
        id="el2s70sw8gldb"
        className="animable"
      />
      <g id="ellbdam306e5">
        <path
          d="M252.1,313.25l16-6.29c-.08-3.81-.21-7.85-.4-12L250,301.38C250.73,305.62,251.46,309.64,252.1,313.25Z"
          style={{
            opacity: 0.2,
            transformOrigin: "259.05px 304.105px",
          }}
          className="animable"
          id="eltzyc14w06i"
        />
      </g>
      <g id="eli08x5ymqxki">
        <path
          d="M289,287.15l-18.5,6.74c1.46,3.62,2.87,7.14,4.19,10.46l17-6.71C290.87,294.25,290,290.73,289,287.15Z"
          style={{
            opacity: 0.2,
            transformOrigin: "281.095px 295.75px",
          }}
          className="animable"
          id="elreayrw188bm"
        />
      </g>
      <path
        d="M242.45,155.23c0,.67-.38,1.2-.82,1.18s-.77-.56-.75-1.22.38-1.2.82-1.19S242.47,154.56,242.45,155.23Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "241.665px 155.205px",
        }}
        id="elfnzgac3eqrh"
        className="animable"
      />
      <g id="elztm72zpahzm">
        <ellipse
          cx={234.13}
          cy={154.99}
          rx={1.21}
          ry={0.79}
          style={{
            fill: "rgb(38, 50, 56)",
            transformOrigin: "234.13px 154.99px",
            transform: "rotate(-88.37deg)",
          }}
          className="animable"
          id="eltuju1yr30u"
        />
      </g>
      <path
        d="M234.36,153.83l-1.59-.51S233.56,154.6,234.36,153.83Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "233.565px 153.698px",
        }}
        id="elbcs7ojnyp3e"
        className="animable"
      />
      <path
        d="M236.88,156.22a17.68,17.68,0,0,1-2.5,4.18,2.88,2.88,0,0,0,2.37.52Z"
        style={{
          fill: "rgb(255, 86, 82)",
          transformOrigin: "235.63px 158.604px",
        }}
        id="eld2uwnnn3hwf"
        className="animable"
      />
      <path
        d="M239.11,163.4a6.67,6.67,0,0,1-1.12.07.21.21,0,0,1-.19-.2.19.19,0,0,1,.2-.19,5.28,5.28,0,0,0,4.36-1.94.19.19,0,1,1,.32.22A5.44,5.44,0,0,1,239.11,163.4Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "240.257px 162.264px",
        }}
        id="elo7z9bmxqj1"
        className="animable"
      />
      <path
        d="M256.19,157.41a5.93,5.93,0,0,1-2.74,3.67c-1.81,1.06-3.25-.43-3.19-2.42.05-1.79,1.06-4.5,3.1-4.75A2.68,2.68,0,0,1,256.19,157.41Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "253.291px 157.663px",
        }}
        id="el2j7ki2s8if9"
        className="animable"
      />
      <path
        d="M245.25,151.79a.35.35,0,0,1-.29-.1,3.11,3.11,0,0,0-2.7-.86.4.4,0,0,1-.18-.77,3.93,3.93,0,0,1,3.41,1.06.38.38,0,0,1,0,.55A.35.35,0,0,1,245.25,151.79Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "243.718px 150.898px",
        }}
        id="el1vevh9zhyz2"
        className="animable"
      />
      <path
        d="M233,151.16a.36.36,0,0,1-.24-.16.38.38,0,0,1,.1-.54,3.88,3.88,0,0,1,3.53-.54.39.39,0,0,1,.21.51.39.39,0,0,1-.51.22,3.07,3.07,0,0,0-2.79.45A.4.4,0,0,1,233,151.16Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "234.659px 150.441px",
        }}
        id="el6lscf3gzsnk"
        className="animable"
      />
      <path
        d="M241.89,154l-1.59-.5S241.09,154.81,241.89,154Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "241.095px 153.882px",
        }}
        id="elaz3yfg01ph"
        className="animable"
      />
      <path
        d="M237.44,239.12l11.61,68.66,43.47-12.89C289,258.6,280,240.63,272.15,234Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "264.98px 270.89px",
        }}
        id="elz47ygty1rac"
        className="animable"
      />
      <g id="elk41bzmrvuy">
        <polygon
          points="255.05 277.7 263.74 263.42 262.25 253.37 255.05 277.7"
          style={{
            opacity: 0.2,
            transformOrigin: "259.395px 265.535px",
          }}
          className="animable"
          id="el7nl99iaygu6"
        />
      </g>
      <path
        d="M267.53,182.54c.74.66,1.65,1.42,2.49,2.12s1.75,1.42,2.65,2.1c1.79,1.35,3.59,2.68,5.46,3.88a43.7,43.7,0,0,0,11.55,5.55c.24.08.49.12.73.18l.36.09c.11,0,0,0,0,0h-.14c-.4,0-.55.18-.44.14a5.31,5.31,0,0,0,1.45-1.46,33.88,33.88,0,0,0,3.14-5.56c1-2,1.88-4.15,2.71-6.31s1.68-4.35,2.46-6.51l0,0a2.1,2.1,0,0,1,4.06,1A84.84,84.84,0,0,1,300.73,192a36.22,36.22,0,0,1-3.16,7.13,12.33,12.33,0,0,1-3.3,3.74,6.66,6.66,0,0,1-3.76,1.22,3.88,3.88,0,0,1-.59,0l-.47-.06-.51-.09c-.33-.06-.68-.1-1-.18a37.21,37.21,0,0,1-7.5-2.44,53.74,53.74,0,0,1-6.73-3.54,71.53,71.53,0,0,1-6.19-4.2q-1.49-1.12-2.91-2.31c-1-.81-1.86-1.58-2.86-2.53a4.23,4.23,0,0,1,5.72-6.22Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "282.281px 189.727px",
        }}
        id="elw96y2b9feg"
        className="animable"
      />
      <path
        d="M262.52,179.09c6-.35,8.28,2.77,10.75,6.13-1.22,5.36-7.72,14.53-7.72,14.53S251.71,179.73,262.52,179.09Z"
        style={{
          fill: "#573A87",
          transformOrigin: "265.831px 189.407px",
        }}
        id="elu91v1n8l7qj"
        className="animable"
      />
      <path
        d="M299.64,177.25l1.5-8.81,5.43,6.42s-1.9,5-5.31,5.39Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "303.105px 174.345px",
        }}
        id="eldi29yfw0g4g"
        className="animable"
      />
      <path
        d="M306.1,166.05l3.26,2.86a1.56,1.56,0,0,1,.25,2.06L306,176.08l-4.85-7.64,3-2.42A1.54,1.54,0,0,1,306.1,166.05Z"
        style={{
          fill: "rgb(255, 139, 123)",
          transformOrigin: "305.519px 170.883px",
        }}
        id="eltvvp4f97ltk"
        className="animable"
      />
      <path
        d="M248.92,414.61a11.5,11.5,0,0,1,3.34-1.68h0s5.92-3.35,9-13.4l6.85-1.71a1.24,1.24,0,0,1,1.53,1.05c.06.54.1,1.16.17,1.66a23.79,23.79,0,0,1,0,3.27c-.06,3.39-.44,11-.44,11h-.79a59.59,59.59,0,0,0-.42-6.93c-.4-2.24-2,.41-2.86,2.94s-4.45,5.24-9.63,5.36C249.62,416.35,247.16,416.08,248.92,414.61Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "259.115px 407.001px",
        }}
        id="elyuqnqqaso0d"
        className="animable"
      />
      <path
        d="M325.28,391a11.55,11.55,0,0,1,1.17-3.55h0s1.81-6.56-3.13-15.84l3.62-6.05a1.23,1.23,0,0,1,1.82-.35c.43.34.9.75,1.3,1a25.3,25.3,0,0,1,2.32,2.31c2.36,2.44,7.5,8.1,7.5,8.1l-.56.57a57.59,57.59,0,0,0-5.21-4.6c-1.86-1.3-1.1,1.69.07,4.1s.56,6.85-3,10.6C327,391.75,325.08,393.3,325.28,391Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "331.6px 378.503px",
        }}
        id="el1mn2y6bj9rb"
        className="animable"
      />
    </g>
    <g
      id="freepik--ID--inject-78"
      className="animable"
      style={{
        transformOrigin: "237.725px 172.804px",
      }}
    >
      <g id="elq737e7ks459">
        <path
          d="M298.92,103.44,193.48,93.33a9.88,9.88,0,0,0-10.73,8.86L167.64,260a9.85,9.85,0,0,0,8.85,10.73l105.43,10.11a9.87,9.87,0,0,0,10.73-8.86l15.12-157.76A9.86,9.86,0,0,0,298.92,103.44Zm-22.57,60.42A36.23,36.23,0,1,1,253,126.44a35.84,35.84,0,0,1,8,4.21,36.19,36.19,0,0,1,15.36,33.21Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.9,
            transformOrigin: "237.707px 187.085px",
          }}
          className="animable"
          id="el4lt6q0g67pf"
        />
      </g>
      <path
        d="M245.63,64.81,257.05,67a5.83,5.83,0,0,1,4.6,6.26l-2.21,20.12a4.89,4.89,0,0,1-.15.78,4.81,4.81,0,0,1-5.61,3.52l-11.42-2.15A5.85,5.85,0,0,1,237.63,90a4.32,4.32,0,0,1,0-.8l2.21-20.12A4.83,4.83,0,0,1,245.63,64.81Zm4.77,9.6a3.84,3.84,0,0,0-4.58,3.42,4.62,4.62,0,0,0,3.66,5,3.83,3.83,0,0,0,4.58-3.42A4.63,4.63,0,0,0,250.4,74.41Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "249.644px 81.242px",
        }}
        id="elcdnt7vdu136"
        className="animable"
      />
      <path
        d="M280.82,107.43a1.23,1.23,0,0,1-1.11-.7l-4.41-9.11a4,4,0,0,0-3.22-2.25l-50.72-4.86a4,4,0,0,0-3.59,1.59l-6.06,8.12a1.23,1.23,0,0,1-2-1.47l6.06-8.12a6.51,6.51,0,0,1,5.8-2.57l50.71,4.86a6.53,6.53,0,0,1,5.21,3.63l4.4,9.11a1.23,1.23,0,0,1-1.1,1.77Z"
        style={{
          fill: "#573A87",
          transformOrigin: "245.742px 97.7313px",
        }}
        id="elibehjiribi"
        className="animable"
      />
      <path
        d="M177,135.54l-.81-.08.56-5.87.82.08ZM178,124.18l-.81-.08,1.27-13.3.82.08Z"
        style={{
          fill: "#573A87",
          transformOrigin: "177.735px 123.17px",
        }}
        id="el0mygqby46ppm"
        className="animable"
      />
      <path
        d="M282.87,281.24a8.21,8.21,0,0,1-1-.05l-105.43-10.1a10.26,10.26,0,0,1-9.22-11.18l15.11-157.76a10.27,10.27,0,0,1,11.18-9.22L299,103a10.27,10.27,0,0,1,9.23,11.18L293.06,272a10.28,10.28,0,0,1-10.19,9.27ZM192.54,93.7a9.45,9.45,0,0,0-9.38,8.53L168,260a9.48,9.48,0,0,0,8.49,10.29L282,280.38a9.45,9.45,0,0,0,10.28-8.49l15.12-157.76a9.47,9.47,0,0,0-8.48-10.29L193.44,93.74C193.14,93.71,192.84,93.7,192.54,93.7Zm47.78,103.38c-1.18,0-2.37-.06-3.56-.17a36.67,36.67,0,1,1,16.36-70.85,35.85,35.85,0,0,1,8.1,4.26,36.62,36.62,0,0,1,15.54,33.58,36.67,36.67,0,0,1-36.44,33.18Zm-.09-72.53a35.85,35.85,0,1,0,35.71,39.27h0A35.8,35.8,0,0,0,260.75,131a34.65,34.65,0,0,0-7.92-4.16,35.33,35.33,0,0,0-9.15-2.11C242.52,124.61,241.37,124.55,240.23,124.55Z"
        style={{
          fill: "#573A87",
          transformOrigin: "237.725px 187.078px",
        }}
        id="elej7vs377i2n"
        className="animable"
      />
      <g id="el0asnuvgx2486">
        <path
          d="M253,126.44a36.27,36.27,0,0,0-45.86,48.67l-34,28.08,9.68-101a9.88,9.88,0,0,1,10.73-8.86l34.94,3.35,11.18,13.56Z"
          style={{
            fill: "#573A87",
            opacity: 0.8,
            transformOrigin: "213.07px 148.24px",
          }}
          className="animable"
          id="ela8pv1fpe66"
        />
      </g>
      <g id="ela7f9icwxaof">
        <path
          d="M307.77,114.17,301,184.66l-25-30.29a36.37,36.37,0,0,0-15-23.72L294.48,103l4.44.43A9.86,9.86,0,0,1,307.77,114.17Z"
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "284.407px 143.83px",
          }}
          className="animable"
          id="elg5w4sfqq6dc"
        />
      </g>
      <path
        d="M294.48,103,261,130.65a35.84,35.84,0,0,0-8-4.21l-13.37-16.2L228.42,96.68Z"
        style={{
          fill: "#573A87",
          transformOrigin: "261.45px 113.665px",
        }}
        id="elbgtqgl36tz"
        className="animable"
      />
      <g id="el8z4kcqpz49j">
        <rect
          x={201.93}
          y={208.97}
          width={66.68}
          height={7.1}
          rx={1.91}
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "235.27px 212.52px",
            transform: "rotate(5.47deg)",
          }}
          className="animable"
          id="elyobdnq25gu"
        />
      </g>
      <g id="elxmcc3cuvb3">
        <rect
          x={205.63}
          y={221.69}
          width={56.84}
          height={7.1}
          rx={1.91}
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "234.05px 225.24px",
            transform: "rotate(5.47deg)",
          }}
          className="animable"
          id="el7lzvujfvyv5"
        />
      </g>
      <g id="el4fq18f2d5x">
        <rect
          x={183.62}
          y={250.17}
          width={15.76}
          height={2.58}
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "191.5px 251.46px",
            transform: "rotate(5.47deg)",
          }}
          className="animable"
          id="elcekohmw5w1m"
        />
      </g>
      <g id="elut6ecmcqt9">
        <rect
          x={202.85}
          y={251.87}
          width={12.71}
          height={2.58}
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "209.205px 253.16px",
            transform: "rotate(5.47deg)",
          }}
          className="animable"
          id="elu4l8bi0b0bl"
        />
      </g>
      <g id="el2lzwys9edld">
        <rect
          x={183.02}
          y={256.94}
          width={36.53}
          height={2.58}
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "201.285px 258.23px",
            transform: "rotate(5.47deg)",
          }}
          className="animable"
          id="el3xzegdxnw85"
        />
      </g>
      <g id="ela9o499pn7ot">
        <rect
          x={184.11}
          y={245.69}
          width={42.85}
          height={2.58}
          style={{
            fill: "#573A87",
            opacity: 0.5,
            transformOrigin: "205.535px 246.98px",
            transform: "rotate(5.71059deg)",
          }}
          className="animable"
          id="eljq8ufw1ndis"
        />
      </g>
      <path
        d="M256,66.53,244.4,64.42a5.34,5.34,0,0,0-6.19,4.33L234.59,88.9a3.66,3.66,0,0,0-.07.8,5.36,5.36,0,0,0,4.37,5.44l11.57,2.11a5.33,5.33,0,0,0,6-3.55,4.08,4.08,0,0,0,.21-.78l3.61-20.15A5.38,5.38,0,0,0,256,66.53ZM248.65,74a4.27,4.27,0,1,1-4.92,3.44A4.28,4.28,0,0,1,248.65,74Z"
        style={{
          fill: "rgb(38, 50, 56)",
          transformOrigin: "247.441px 80.8365px",
        }}
        id="elo1bf1pbxubt"
        className="animable"
      />
      <g id="eluoac0gotxzr">
        <path
          d="M256,66.53,244.4,64.42a5.34,5.34,0,0,0-6.19,4.33L234.59,88.9a3.66,3.66,0,0,0-.07.8,5.36,5.36,0,0,0,4.37,5.44l11.57,2.11a5.33,5.33,0,0,0,6-3.55,4.08,4.08,0,0,0,.21-.78l3.61-20.15A5.38,5.38,0,0,0,256,66.53ZM248.65,74a4.27,4.27,0,1,1-4.92,3.44A4.28,4.28,0,0,1,248.65,74Z"
          style={{
            fill: "rgb(255, 255, 255)",
            opacity: 0.2,
            transformOrigin: "247.441px 80.8365px",
          }}
          className="animable"
          id="elurljgs6qbz"
        />
      </g>
      <g id="elwgd4cjxye1">
        <circle
          cx={273.05}
          cy={259.28}
          r={7.09}
          style={{
            fill: "#573A87",
            opacity: 0.2,
            transformOrigin: "273.05px 259.28px",
            transform: "rotate(-39.53deg)",
          }}
          className="animable"
          id="elj99m53zr0x"
        />
      </g>
      <g id="elzs7n62h3vq">
        <circle
          cx={254.28}
          cy={257.48}
          r={7.09}
          style={{
            fill: "#573A87",
            opacity: 0.8,
            transformOrigin: "254.28px 257.48px",
            transform: "rotate(-39.53deg)",
          }}
          className="animable"
          id="eloyyrmdn7npp"
        />
      </g>
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          result="DILATED"
          operator="dilate"
          radius={2}
        />
        <feFlood floodColor="#ff0000" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix
          type="matrix"
          values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
        />
      </filter>
    </defs>
  </svg>
);
export default EnterAadharAnimation;
