import React, { useState } from "react";

import "../components/distOnboarding/DistPersonalDetails.scss";
import greattrlogo from "./assets/logo.png";
import docuinfo from "./assets/docinfo.png";
import axios from "./utils/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import EnterAadharAnimation from "./SvgComponents/EnterAadharAnimation";
import useStore from "../store";

const EnterAadhaar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  // const { state } = location;

    const [state, setState] = useState(() => {
      if (user && Object.keys(user).length > 0) {
        return user;
      }
  
      const savedState = localStorage.getItem("state");
      console.log(savedState);
      const { state } = location;
      console.log("state: ", state);
      return savedState ? JSON.parse(savedState) : { ...state };
    });

  const role = state?.role;
  console.log("state UserAddhar component ", state);

  const [aadhaarNo, setAadhaarNo] = useState("");
  

  const aadhaarSubmit = async (e) => {
    e.preventDefault();
    var MobileNumber = localStorage.getItem("MobileNumber");
    var GreattrOperatorId = localStorage.getItem("GreattrOperatorId");
    console.log({
      MobileNumber: state?.MobileNumber || MobileNumber,
      AadhaarNumber: aadhaarNo,
      GreattrOperatorId: GreattrOperatorId,
      ...state,
    });

    localStorage.setItem(
      "state",
      JSON.stringify({
        MobileNumber: state?.MobileNumber || MobileNumber,
        AadhaarNumber: aadhaarNo,
        GreattrOperatorId: GreattrOperatorId,
        ...state,
      })
    );


    const updatedState = {
      AadhaarNumber: aadhaarNo,
      ...state,
    };

    setUser(updatedState);

    if (role === "Dist") {
      navigate("/DistFinalSet", {
        state: {
          MobileNumber: state?.MobileNumber || MobileNumber,
          AadhaarNumber: aadhaarNo,
          GreattrOperatorId: GreattrOperatorId,
          ...state,
        },
      });
    } else if (role === "Dealer") {
      navigate("/DistFinalSet", {
        state: {
          MobileNumber: state?.MobileNumber || MobileNumber,
          AadhaarNumber: aadhaarNo,
          GreattrOperatorId: GreattrOperatorId,
          ...state,
        },
      });
    } else {
      navigate("/FinalSet", {
        state: {
          MobileNumber: state?.MobileNumber || MobileNumber,
          AadhaarNumber: aadhaarNo,
          GreattrOperatorId: GreattrOperatorId,
          ...state,
        },
      });
    }
  };

  return (
    <div className="uploaddocs_company-info">
      <div className="uploaddocs_company-info-container">
        <img src={greattrlogo} className="greattrlogo" alt="GreatTR Logo" />
        <br />
        <p style={{ margin: 0 }}>5 / 5</p>
        <p style={{ margin: 0, fontSize: "3.5rem" }}>Enter Aadhaar</p>
        <p style={{ margin: 0, fontSize: "2rem", maxWidth: "600px" }}>
          You have almost completed the registration process. Please enter your
          Aadhaar number to verify your identity with KYC.
        </p>
        <div className="SuccessComponent">
          <EnterAadharAnimation />
        </div>

        <form onSubmit={aadhaarSubmit}>
          <div className="indi-form-group">
            <label htmlFor="aadhaarNo" className="label-font" style={{fontSize: "2.5rem"}} >
              Aadhaar Number*
            </label>
            <br />
            <input
              style={{ padding: 20, borderRadius: 4, fontSize: 16,width: "35rem" }}
              type="text"
              id="aadhaarNo"
              name="aadhaarNo"
              className="placeholder-font"
              value={aadhaarNo}
              placeholder="Enter Aadhaar Number"
              onChange={(e) => setAadhaarNo(e.target.value)}
              required
            />
          </div>
          <br />
          <button
            type="submit"
            className="btn btn-primary form-submit dc-doc-btns-submit-button"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EnterAadhaar;
