import React, { useEffect, useState } from "react";
import StepIndicator from "./StepIndicator";
import greattrlogo from "../assets/logo.png";
import useStore from "../../store";
import { useNavigate } from "react-router-dom";
import axios from "../../components/utils/axiosConfig";
import { GESRecords } from "../../Utilsx/Encrypt";
let apiEN = process.env.REACT_APP_API_apiEN;

let initialSteps = [
  {
    name: "Company Information",
    status: "Incomplete",
    dbName: "CompanyInformationStatus",
    navPath: "/DistCompanyInfo",
  },
  {
    name: "Business Information",
    status: "Incomplete",
    dbName: "BusinessInformationStatus",
    navPath: "/DistBusinessDetails",
  },
  {
    name: "Document Upload",
    status: "Incomplete",
    dbName: "DocumentUploadStatus",
    navPath: "/DistUploadDocuments",
  },
  {
    name: "Basic Information",
    status: "Incomplete",
    dbName: "BasicInformationStatus",
    navPath: "/DistPersonalDetails",
  },
  {
    name: "Payment Details",
    status: "Incomplete",
    dbName: "PaymentDetailStatus",
    navPath: "/DistCompanyInfoOperator",
  },
  {
    name: "Aadhar KYC",
    status: "Incomplete",
    dbName: "AadharKycStatus",
    navPath: "/EnterAadhaar",
  },
];

function IsStepsComplete({ errorStatus }) {
  const user = useStore((state) => state.user);
  const setNavStack = useStore((state) => state.setNavStack);
  const emptyNavStack = useStore((state) => state.emptyNavStack);

  const popNavStack = useStore((state) => state.popNavStack);
  const setUser = useStore((state) => state.setUser);

  const [currentStep, setCurrentStep] = useState(null);
  const [steps, setSteps] = useState(initialSteps);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileStatus = async () => {
      try {
        const encryptedData = GESRecords({ mobile: user.MobileNumber }, apiEN);
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/GetMerchantProfileStatus`,
          encryptedData
        );
        const apiData = response.data.status;
        const updatedSteps = steps.map((step) => ({
          ...step,
          status: apiData[step.dbName] || step.status,
        }));
        setSteps(updatedSteps);

       
        

        // Determine if all steps are completed
        const allStepsCompleted = updatedSteps.every(step => step.status === "Completed");

        const navStack = updatedSteps
          .filter((step) => step.status !== "Completed")
          .map((step) => step.navPath);


 
        // Add /DistAccountCreatedSuccessfully to NavStack if all steps are completed
        if (allStepsCompleted ||apiData["AadharKycStatus"] === "Completed") {
          navStack.push("/DistAccountCreatedSuccessfully");
        }

        console.log("navStack", navStack);
        setNavStack(navStack);
      } catch (error) {
        console.error(
          "Error fetching profile status:",
          error.response ? error.response.data : error.message
        );
      }
    };

    const statusArray = errorStatus ? errorStatus.split(",") : [];
    const updatedSteps = initialSteps.map((step, index) => ({
      ...step,
      status: index < statusArray.length ? statusArray[index].trim() : step.status,
    }));
    setSteps(updatedSteps);
    fetchProfileStatus();
  }, [errorStatus, user.MobileNumber, setUser, setNavStack]);

  const handleStepClick = (index) => {
    setCurrentStep(index);
    const clickedStep = steps[index];
    const updatedNavStack = [
      clickedStep.navPath,
      ...user.NavStack.filter((path) => path !== clickedStep.navPath),
    ];
    setNavStack(updatedNavStack);
  };

  const handleNavigate = () => {
    if (steps[0].status === "Incomplete") {
      emptyNavStack();
      navigate("/DistCompanyInfo");
    } else {
      const nextNav = user.NavStack[0];

      if (nextNav) {
        popNavStack(); // Remove the top item from NavStack

        setTimeout(() => {
          navigate(nextNav);
        }, 100); // Adjust delay as needed
      } else {
        console.warn("Navigation stack is empty. Redirecting to default page.");
        navigate("/DistAccountCreatedSuccessfully");
      }
    }
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#f0f2f5",
        minHeight: "100vh",
      }}
    >
      <img
        className="greattrlogo"
        src={greattrlogo}
        alt="Logo"
        style={{ marginBottom: "20px" }}
      />
      <h2
        style={{
          color: "#333",
          marginBottom: "10px",
          fontWeight: "bold",
          fontSize: "2.5rem",
        }}
      >
        Onboarding Progress
      </h2>
      {/* <h3 style={{ marginBottom: "20px", color: "#555" }}>{user.role}</h3> */}

      <StepIndicator steps={steps} onStepClick={handleStepClick} />

      <button
        className="DistCompanyInfoBtn"
        type="submit"
        style={{
          marginTop: "20px",
          padding: "1rem",
          marginLeft: "0.5rem",
          marginRight: "0.5rem",
          fontSize: "1.8rem",
          maxWidth: "500px",
          minWidth: isMobile ? "300px" : "500px",
          color: "#fff",
          backgroundColor: "#573a87",
          border: "none",
          borderRadius: "5px",
          fontFamily: '"Manrope", sans-serif',
          fontOpticalSizing: "auto",
          fontWeight: "400",
          fontStyle: "normal",
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#472a70")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#573a87")}
        onClick={handleNavigate}
      >
        Continue
      </button>
    </div>
  );
}

export default IsStepsComplete;
