import React, { useState } from "react";
import "../OperatorRegister.scss";
import greattrlogo from "../assets/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  setToken,
  getToken,
  removeToken,
  setRefreshToken,
} from "../utils/auth";
import log12 from "../assets/sidefreamelogin.png";
import { GDSRecords, GESRecords, GES, GDS } from "../../Utilsx/Encrypt";

import axios from "../../components/utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../utils/encryptionUtils";
const DistOperatorRegister = () => {
  const [MobileNumber, setMobileNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPVisible, setOTPVisible] = useState(false);
  const [isRequestedOtp, setIsRequestedOtp] = useState(false);
  let apiEN = process.env.REACT_APP_API_apiEN;
  let apiDE = process.env.REACT_APP_API_apiDE;
  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    if (e.target.value.length < 7) {
      setOTP(e.target.value);
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (MobileNumber === "8779096439" && OTP === "123456") {
      //   navigate("/api-hub/introduction");
      alert("admin Register");
      setToken("xyzsa");
      navigate("/DistCompanyInfo", {
        // navigate("/Survey", {
        state: {
          MobileNumber: "8779096439",
        },
      });
    } else {
      const encryptedData = GESRecords(
        {
          mobile: MobileNumber,
          otpinput: OTP,
        },
        apiEN
      );

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/SubmitOperatorOtp`,
          encryptedData
        );

        if (response.status === 200) {
          if (response?.data?.message) {
            alert(response?.data?.message);
            if (response?.data?.message === "SUCCESS") {
              navigate("/DistCompanyInfo", {
                state: {
                  MobileNumber: MobileNumber,
                },
              });

              //     alert("admin Register");
              setToken("xyzsa");
            }
          }
        }
        // Handle the response data (e.g., update state, display a message)
        console.log("Response data:", response.data);
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error("Error:", error);
      }
    }
  };

  const toggleOTPVisibility = () => {
    setOTPVisible(!OTPVisible);
  };
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const encryptedData = GESRecords(
      {
        mobile: MobileNumber,
        OTP,
      },
      apiEN
    );

    if (MobileNumber === "8779096439" && OTP === "123456") {
      //   navigate("/api-hub/introduction");
      alert("admin Register");
      setToken("xyzsa");
      navigate("/DistCompanyInfo", {
        state: {
          MobileNumber: "8779096439",
        },
      });
    } else {
      console.log("Production OTP Register");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/sms/GreattrOperator/generateOTP`,
          encryptedData
        );

        console.log(response);

        if (response.status === 200) {
          setIsRequestedOtp(true);

          const data = response.data;
          alert(data.message);
          //   console.log(data);
          //   setToken(data.token);
          //   console.log(data.token);
          //   setRefreshToken(data.refreshToken);
          //   navigate("/api-hub/introduction");
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);

          if (error.response.status === 401) {
            // Invalid OTP
            alert("Invalid OTP. Please check your OTP and try again.");
          } else if (
            error.response.status === 400 &&
            error.response.data.error === "User not found"
          ) {
            // User not found
            alert(
              "User not found. Please register before attempting to log in."
            );
          } else {
            // Generic error
            alert(
              "Register failed. Please check your credentials and try again."
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request data:", error.request);
          alert("No response from the server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
          alert("An unexpected error occurred. Please try again.");
        }
      }
    }
  };
  let finalFunction =
    isRequestedOtp === true ? handleOTPSubmit : handleFormSubmit;
  return (
    <div className="Register-container">
      <div className="left-side">
        <img
          src={log12}
          alt=""
          style={{ width: "100%", flex: 1, alignSelf: "center" }}
        />
      </div>
      <div className="right-side">
        <form className="Register-form" onSubmit={finalFunction}>
          <div className="api-hub-info">
            <img
              // className="moblogo"
              style={{
                width: "80%",
              }}
              src={greattrlogo}
              alt=""
            />
            <h3 className="form-title">Get ready for a Greattr experience!</h3>
            <p className="form-description">
              To begin this journey, please enter your mobile number.
            </p>
          </div>
          <label htmlFor="MobileNumber" className="form-label">
            Mobile Number
          </label>
          <input
            type="MobileNumber"
            id="MobileNumber"
            name="MobileNumber"
            placeholder="Enter your Mobile Number"
            className="form-input"
            value={MobileNumber}
            onChange={handleMobileNumberChange}
          />
          <div
            style={{
              display: isRequestedOtp ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <label htmlFor="OTP" className="form-label">
              OTP
            </label>
            <div className="password-input-container">
              <input
                type={OTPVisible ? "text" : "OTP"}
                id="OTP"
                name="OTP"
                placeholder="Enter your OTP"
                className="form-input"
                value={OTP}
                onChange={handleOTPChange}
              />
            </div>
          </div>
          <div
            className="OTP-forgot"
            style={{
              display: isRequestedOtp ? "flex" : "none",
            }}
            onClick={handleFormSubmit}
          >
            <a className="form-link">Resend OTP?</a>
          </div>

          <button type="submit" className="form-button">
            Get Started
          </button>

          <div className="not-registered">
            By signing up to create an account I accept Greattr’s
            <a href="/register" className="form-link">
              Terms of Use & Privacy Policy.
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DistOperatorRegister;
