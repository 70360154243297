import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SurveyOTP from "./components/SurveyOTP";
import Survey from "./components/Survey";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.scss";

import AllSet from "./components/AllSet";
import DeleteAccount from "./components/DeleteAcc";
import Register from "./components/Register";
import OperatorLogin from "./components/OperatorLogin";
import "./custom_c.scss";
import "./custom.scss";
import CompanyInfo from "./components/CompanyInfo";
import CompanyInfoOperator from "./components/CompanyInfoOperator";
import PersonalDetails from "./components/PersonalDetails";
import BusinessDetailsForm from "./components/BusinessDetails";
import FinalSet from "./components/FinalSet";
import AccountCreatedSuccessfully from "./components/AccountCreatedSuccessfully";
import UploadDocuments from "./components/UploadDocuments";
import OperatorRegister from "./components/OperatorRegister";
import axios from "axios";
import NavContext from "./Utilsx/navUtils";
import EnterAadhaar from "./components/EnterAadhaar";
import EnterAadharSafetycheck from "./components/EnterAadharSafetycheck";

import DistOperatorLogin from "./components/distOnboarding/DistOperatorLogin";
import DistOperatorRegister from "./components/distOnboarding/DistOperatorRegister";
import DistCompanyInfo from "./components/distOnboarding/DistCompanyInfo";
import DistPersonalDetails from "./components/distOnboarding/DistPersonalDetails";
import DistBusinessDetails from "./components/distOnboarding/DistBusinessDetails";
import DistCompanyInfoOperator from "./components/distOnboarding/DistCompanyInfoOperator";
import DistUploadDocuments from "./components/distOnboarding/DistUploadDocuments";
import DistFinalSet from "./components/distOnboarding/DistFinalSet";
import DistAccountCreatedSuccessfully from "./components/distOnboarding/DistAccountCreatedSuccessfully";
import DistLogin from "./components/distOnboarding/MerchantLogin";
import MerchantLogin from "./components/distOnboarding/MerchantLogin";
// import DistSafetyCheckOperatorLogin from "./components/distSafetyCheckOnboarding/DistSafetyCheckOperatorLogin";
import DistSafetyCheckOperatorRegister from "./components/distSafetyCheckOnboarding/DistSafetyCheckOperatorRegister";
import DistSafetyCheckCompanyInfo from "./components/distSafetyCheckOnboarding/DistSafetyCheckCompanyInfo";
import DistSafetyCheckPersonalDetails from "./components/distSafetyCheckOnboarding/DistSafetyCheckPersonalDetails";
import DistSafetyCheckBusinessDetails from "./components/distSafetyCheckOnboarding/DistSafetyCheckBusinessDetails";
import DistSafetyCheckCompanyInfoOperator from "./components/distSafetyCheckOnboarding/DistSafetyCheckCompanyInfoOperator";
import DistSafetyCheckUploadDocuments from "./components/distSafetyCheckOnboarding/DistSafetyCheckUploadDocuments";
import DistSafetyCheckFinalSet from "./components/distSafetyCheckOnboarding/DistSafetyCheckFinalSet";
import DistSafetyCheckAccountCreatedSuccessfully from "./components/distSafetyCheckOnboarding/DistSafetyCheckAccountCreatedSuccessfully";
import SafetyCheckMerchantLogin from "./components/distSafetyCheckOnboarding/SafetyCheckMerchantLogin";
import SafetyCheckEnterAadhaar from "./components/distSafetyCheckOnboarding/SafetyCheckEnterAadhaar";
import FinalScreen from "./components/distSafetyCheckOnboarding/FinalScreen";
import FinalScreenMechant from "./components/FinalScreenMechant";
import FinalScreenMechant222 from "./components/FinalScreenMechant222";
import LeftContent from "./components/distSafetyCheckOnboarding/components/LeftContent";
import ProtectedRoute from "./components/ProtectedRoute";
import { getToken } from "./components/utils/auth";
import DistCreatedSafetyCheck from "./components/distOnboarding/DistCreatedSafetyCheck";
import Website from "./components/LandPage/Website";
import OperatorCustomerList from "./components/OperatorCustomerList";
import DefaultTailwind from "./components/DefaultTailwind";
import IsStepsComplete from "./components/IsStepsComplete/IsStepsComplete";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const { isLogin, setisLogin, isOperatorLogin, setisOperatorLogin } =
    useContext(NavContext);

  let authToken = getToken();

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const [authorized, setAuthorized] = useState(false);
  console.log("ioeratir--------------", isOperatorLogin);
  const [accessProtectedRoute, setAccessProtectedRoute] = useState(false);

  const handleLogout = async () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      // Clear all items from localStorage
      localStorage.clear();
      // Optionally, perform additional logout logic like redirecting
      // Replace this with your actual logout logic

      console.log("Logging out...");
      window.location.href = "/DistOperatorLogin";
    } else {
      // Optionally, handle cancellation of logout
      console.log("Logout cancelled.");
    }
  };

  const [MobileNumber, setMobileNumber] = useState("");
  useEffect(() => {
    const verifyToken = async (token) => {
      try {
        if (!token) {
          // Handle case where token is not present
          setAuthorized(false);

          return;
        }

        // Send token to backend for verification
        const response = await axios.get(
          `${process.env.REACT_APP_API_LINK}/protectedRoute`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response.data); // Log response for verification
        if (response.data.message === "Access granted!") {
          setAuthorized(true);
          setAccessProtectedRoute(true);
          setMobileNumber(localStorage.getItem("MobileNumber"));
        }
        // If backend verifies the token, set authorized to true
      } catch (error) {
        console.error("Token verification failed:", error);
        // Handle error scenario, e.g., redirect to login page
        setAuthorized(false);
      }
    };

    const verifyLoginToken = async () => {
      if (!token) {
        // Handle case where token is not present
        setAuthorized(false);
        setAccessProtectedRoute(false);
        return;
      } else if (token) {
        setAccessProtectedRoute(true);
      }
    };
    const token = localStorage.getItem("your_app_token"); // Assuming token is stored in localStorage
    console.log(token);
    if (token && token !== "null") {
      verifyToken(token);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("your_app_token");

    const verifyLoginToken = async (token) => {
      if (!token) {
        alert("Logging you out ");
        setAccessProtectedRoute(false);
        setisLogin(false);
        return;
      } else if (token) {
        setAccessProtectedRoute(true);
      }
    };
    if (token && token !== "null") {
      verifyLoginToken(token);
    }
    if (token === null) {
      setAccessProtectedRoute(false);
    }
  }, [isLogin]);

  return (
    <Router>
      <div>
        {/* <Navigation /> */}
        <Routes>
          <Route path="/" element={<Website />} />
          {/* <Route path="/" element={<DefaultTailwind />} /> */}
          <Route path="/IsOnboardingComplete" element={<IsStepsComplete />} />

          {/* Select Flow*/}
          <Route path="/OperatorLogin" element={<OperatorLogin />} />
          <Route
            path="/MerchantLogin"
            element={<MerchantLogin setisLogin={setisLogin} />}
          />

          <Route
            path="/safetycheck/SafetyCheckMerchantLogin"
            element={<SafetyCheckMerchantLogin setisLogin={setisLogin} />}
          />

          {/* <Route
            path="/safetycheck/DistSafetyCheckOperatorLogin"
            element={<DistSafetyCheckOperatorLogin setisLogin={setisLogin} />}
          /> */}

          <Route
            path="/DistOperatorLogin"
            element={<DistOperatorLogin setisLogin={setisLogin} />}
          />

          <Route
            path="DistCreatedSafetyCheck"
            element={<DistCreatedSafetyCheck setisLogin={setisLogin} />}
          />
          <Route
            path="/OperatorRegister"
            element={<OperatorRegister setisLogin={setisLogin} />}
          />
          <Route
            path="/DistOperatorRegister"
            setisLogin={setisLogin}
            element={<DistOperatorRegister />}
          />

          <Route
            path="/safetycheck/DistOperatorRegister"
            setisLogin={setisLogin}
            element={<DistSafetyCheckOperatorRegister />}
          />

          {/* Protected routes */}
          {/* <Route
            element={
              <ProtectedRoute accessProtectedRoute={accessProtectedRoute} />
            }
          > */}
          <Route path="/" element={<Website />} />
          <Route path="/DistCompanyInfo" element={<DistCompanyInfo />} />
          <Route
            path="/safetycheck/DistSafetyCheckCompanyInfo"
            element={<DistSafetyCheckCompanyInfo />}
          />
          <Route
            path="/CompanyInfoOperator"
            element={<CompanyInfoOperator />}
          />
          <Route
            path="/DistCompanyInfoOperator"
            element={<DistCompanyInfoOperator />}
          />
          <Route
            path="/safetycheck/DistSafetyCheckCompanyInfoOperator"
            element={<DistSafetyCheckCompanyInfoOperator />}
          />
          <Route path="/PersonalDetails" element={<PersonalDetails />} />
          <Route
            path="/DistPersonalDetails"
            element={<DistPersonalDetails />}
          />
          <Route
            path="/safetycheck/DistSafetyCheckPersonalDetails"
            element={<DistSafetyCheckPersonalDetails />}
          />
          <Route path="/BusinessDetails" element={<BusinessDetailsForm />} />
          <Route
            path="/DistBusinessDetails"
            element={<DistBusinessDetails />}
          />
          <Route
            path="/safetycheck/DistSafetyCheckBusinessDetails"
            element={<DistSafetyCheckBusinessDetails />}
          />
          <Route path="/FinalSet" element={<FinalSet />} />
          <Route path="/DistFinalSet" element={<DistFinalSet />} />
          <Route
            path="/safetycheck/DistSafetyCheckFinalSet"
            element={<DistSafetyCheckFinalSet />}
          />
          <Route
            path="/AccountCreatedSuccessfully"
            element={<AccountCreatedSuccessfully />}
          />
          <Route
            path="/DistAccountCreatedSuccessfully"
            element={<FinalScreenMechant />}
          />
          <Route
            path="/safetycheck/DistAccountCreatedSuccessfully"
            element={<DistAccountCreatedSuccessfully />}
          />
          <Route
            path="/safetycheck/DistSafetyCheckAccountCreatedSuccessfully"
            element={<DistSafetyCheckAccountCreatedSuccessfully />}
          />
          <Route path="/UploadDocs" element={<UploadDocuments />} />
          <Route
            path="/DistUploadDocuments"
            element={<DistUploadDocuments />}
          />
          <Route
            path="/safetycheck/DistSafetyCheckUploadDocuments"
            element={<DistSafetyCheckUploadDocuments />}
          />

          <Route path="/safetycheck/FinalScreen" element={<FinalScreen />} />

          <Route path="/FinalScreenMechant" element={<FinalScreenMechant />} />

          <Route
            path="/FinalScreenMechant222"
            element={<FinalScreenMechant222 />}
          />

          {/* <Route path="/safetycheck/LeftContent" element={<LeftContent />} /> */}

          <Route path="/EnterAadhaar" element={<EnterAadhaar />} />
          <Route
            path="/safetycheck/EnterAadhaar"
            element={<EnterAadharSafetycheck />}
          />

         
          <Route
            path="/safetycheck/SafetyCheckEnterAadhaar"
            element={<SafetyCheckEnterAadhaar />}
          />
          {/* <Route path="/Register" element={<Register />} /> */}
          <Route path="/deleteAccount" element={<DeleteAccount />} />
          <Route path="/ManageAccount" element={<DeleteAccount />} />
          {/* </Route> */}
        </Routes>
      </div>
      {isOperatorLogin ? (
        <>
          <div
            style={{
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <p
              style={{ margin: 0, fontWeight: "bold" }}
            >{`Logged in as ${MobileNumber}`}</p>
            <p
              style={{
                // padding: '8px 16px',
                margin: 0,
                // backgroundColor: '#3498db',
                color: "black",
                display: "flex",
                border: "none",
                borderRadius: "3px",
                cursor: "pointer",
              }}
              onClick={handleLogout}
            >
              Logout
            </p>
          </div>

          <Routes>
            <Route
              path="/OperatorCustomers"
              element={
                <OperatorCustomerList landingPageData={landingPageData} />
              }
            />
            <Route
              path="/survey"
              element={<Survey landingPageData={landingPageData} />}
            />
            <Route
              path="/surveyotp"
              element={<SurveyOTPPage landingPageData={landingPageData} />}
            />
          </Routes>
        </>
      ) : (
        <></>
      )}
      <Routes>
        <Route
          path="/allset"
          element={<AllSetPage landingPageData={landingPageData} />}
        />
      </Routes>
    </Router>
  );
};

const HomePage = ({ landingPageData }) => (
  <div>
    <Header data={landingPageData.Header} />
    {/* <Features data={landingPageData.Features} /> */}
    <About data={landingPageData.About} />
    {/* <Services data={landingPageData.Services} /> */}
    {/* <Gallery data={landingPageData.Gallery} /> */}
    {/* <Testimonials data={landingPageData.Testimonials} /> */}
    <Team data={landingPageData.Team} />
    <Contact data={landingPageData.Contact} />
  </div>
);

const SurveyOTPPage = ({ landingPageData }) => (
  <div>
    <SurveyOTP
      data={landingPageData.SurveyOTP} // Assuming SurveyOTP component uses data prop
    />
  </div>
);

const AllSetPage = ({ landingPageData }) => (
  <div>
    <AllSet
      data={landingPageData.AllSet} // Assuming SurveyOTP component uses data prop
    />
  </div>
);

export default App;
