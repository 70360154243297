import React, { useState, useContext } from "react";
import "./MerchantLogin.scss";
import useStore from "../../store";

import greattrlogo from "../assets/logo.png";

import {
  setToken,
  getToken,
  removeToken,
  setRefreshToken,
} from "../utils/auth";

import { validateMobileNo } from "../utils/validations";
import { GDSRecords, GESRecords, GES, GDS } from "../../Utilsx/Encrypt";

import axios from "../../components/utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import NavContext from "../../Utilsx/navUtils";
import LeftContentLogin from "./LeftContentLogin";
import { Form, InputGroup } from "react-bootstrap";
import TextInputWrapper from "../components/TextInputWrapper";
const MerchantLogin = () => {
  const setUser = useStore((state) => state.setUser);
  const [MobileNumber, setMobileNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPVisible, setOTPVisible] = useState(false);
  const [isRequestedOtp, setIsRequestedOtp] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(1);
  let selectedCompanyName = "BPCL";
  const [company, setCompany] = useState("BPCL");
  let apiEN = process.env.REACT_APP_API_apiEN;

  const handleSelect = (id) => {
    setSelectedCompany(id);
    selectedCompanyName =
      companies.find((company) => company.id === id)?.name || "None";
    console.log("select compnay ", selectedCompanyName);
    setCompany(selectedCompanyName);
  };

  const companies = [
    {
      id: 1,
      name: "BPCL",
      image: process.env.PUBLIC_URL + "/img/BPCL.png",
    },
    {
      id: 2,
      name: "TOTAL",
      image: process.env.PUBLIC_URL + "/img/TOTAL.png",
    },

    { id: 3, name: "HPCL", image: process.env.PUBLIC_URL + "/img/HPCL.png" },
    {
      id: 4,
      name: "IOCL",
      image: process.env.PUBLIC_URL + "/img/IOCL.png",
    },
  ];
  const handleMobileNumberChange = (e) => {
    var stext = e.target.value + "";
    stext = stext.replace(/['";\-\-]/g, ""); // Escaping the hyphen
    stext = stext.replace(/\D/g, "");
    if (stext.length < 11) {
      setMobileNumber(stext);
    }
  };

  const handleOTPChange = (otp) => {
    if (otp.length < 7) {
      setOTP(otp);
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (!OTP) {
      alert("Please enter a valid OTP input");
      return;
    }
    if (MobileNumber === "8779096439" && OTP === "123456x") {
      alert("admin login");
      setToken("xyzsa");
      navigate("/DistCompanyInfo", {
        state: {
          MobileNumber: "8779096439",
        },
      });
    } else {
      setUser({});
      const encryptedData = GESRecords(
        {
          mobile: MobileNumber,
          otpinput: OTP,
        },
        apiEN
      );

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/MerchantOtpLogin`,
          encryptedData
        );

        if (response.status === 200) {
          if (response?.data?.message) {
            if (response?.data?.message === "SUCCESS") {
              console.log(response.data);

              setToken(response.data.token);
              let GreattrDistID = response.data.GreattrDistID;
              let GreattrDealerID = response.data.GreattrDealerID;
              let DocumentReupload = response.data.DocumentReupload;

              localStorage.setItem("MobileNumber", MobileNumber);
              localStorage.setItem("GreattrDistId", GreattrDistID);
              const loginData = {
                MobileNumber: MobileNumber,
                parentCompany: company,
                GreattrDistId: GreattrDistID,
                GreattrDistID: GreattrDistID,
                DocumentReupload: DocumentReupload,
                GreattrDealerID: GreattrDealerID,
                currentNextNav: response.data.navigate,
                role: response?.data?.role || "Dist",
              };
              setUser({ ...loginData, lastlogin: Date.now() });
              navigate("/IsOnboardingComplete", {
                state: { ...loginData },
              });
              // window.location.reload();

              //     alert("admin login");
            } else {
              alert(response?.data?.message);
            }
          }
        }
        // Handle the response data (e.g., update state, display a message)
        console.log("Response data:", response.data);
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error("Error:", error);
      }
    }
  };

  const toggleOTPVisibility = () => {
    setOTPVisible(!OTPVisible);
  };
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateMobileNo(MobileNumber)) {
      alert("Please enter a valid 10 digit mobile number");
      return;
    }

    const encryptedData = GESRecords(
      {
        mobile: MobileNumber,
        OTP,
      },
      apiEN
    );

    if (MobileNumber === "8779096x439" && OTP === "123456") {
      //   navigate("/api-hub/introduction");
      alert("admin login");
      setIsRequestedOtp(true);
      // setToken("xyzsa");
      // navigate("/PersonalDetails", {
      //     state: {
      //         MobileNumber: "8779096439",
      //     },
      // });
    } else {
      console.log("Production OTP login");
      try {
        console.log("inside1");
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}/sms/GreattrMerchant/generateOTP`,
          encryptedData
        );

        console.log("response:", response);

        if (response.status === 200) {
          setIsRequestedOtp(true);

          const data = response.data;
          alert(data.message);
          //   console.log(data);
          //   setToken(data.token);
          //   console.log(data.token);
          //   setRefreshToken(data.refreshToken);
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);

          if (error.response.status === 401) {
            // Invalid OTP
            alert("Invalid OTP. Please check your OTP and try again.");
          } else if (
            error.response.status === 400 &&
            error.response.data.error === "User not found"
          ) {
            // User not found
            alert(
              "User not found. Please register before attempting to log in."
            );
          } else {
            // Generic error
            alert("Login failed. Please check your credentials and try again.");
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request data:", error.request);
          alert("No response from the server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
          alert("An unexpected error occurred. Please try again.");
        }
      }
    }
  };
  let finalFunction =
    isRequestedOtp === true ? handleOTPSubmit : handleFormSubmit;
  return (
    <div className="auth-container">
      <div className="left-section">
        <LeftContentLogin />
      </div>

      <div className="auth-section">
        <form className="auth-form" onSubmit={finalFunction}>
          <div className="brand-info">
            <img
              style={{
                width: "80%",
              }}
              src={greattrlogo}
              alt=""
            />
            <h3 className="form-heading">
              Get ready for a Greattr experience!
            </h3>
            <p className="form-subheading">
              To begin this journey, please enter your mobile number.
            </p>
          </div>

          <p className="form-subheading" style={{ textAlign: "center" }}>
            Select Parent Company
          </p>
          <div className="companies">
            {companies.map((company) => (
              <div
                key={company.id}
                className={`company ${
                  selectedCompany === company.id ? "selected" : ""
                }`}
                onClick={() => handleSelect(company.id)}
              >
                <img src={company.image} alt={company.name} />
              </div>
            ))}
          </div>

          <div className="mb-3">
            <br />
            <label htmlFor="MobileNumber" className="input-label">
              Mobile Number
            </label>
            <InputGroup
              style={
                {
                  // fontSize: '2rem'
                }
              }
            >
              <InputGroup.Text
                id="basic-addon1"
                style={{
                  fontSize: "1.52rem",
                }}
              >
                +91&nbsp;
              </InputGroup.Text>
              <Form.Control
                type="text"
                id="MobileNumber"
                name="MobileNumber"
                placeholder="&nbsp;&nbsp;&nbsp;Enter your Mobile Number"
                className="input-fieldx"
                value={MobileNumber}
                onChange={handleMobileNumberChange}
                aria-label="MobileNumber"
                aria-describedby="basic-addon1"
                style={{
                  fontSize: "1.52rem",
                }}
              />
            </InputGroup>
          </div>
          <div
            style={{
              display: isRequestedOtp ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <label htmlFor="OTP" className="input-label">
              OTP
            </label>
            <div className="otp-input-wrapper">
              <TextInputWrapper
                type={OTPVisible ? "text" : "OTP"}
                id="OTP"
                name="OTP"
                placeholder="Enter your OTP"
                className="input-field"
                value={OTP}
                onChange={handleOTPChange}
              />
            </div>
          </div>
          <div
            className="otp-resend"
            style={{
              display: isRequestedOtp ? "flex" : "none",
              cursor: "pointer",
            }}
            onClick={handleFormSubmit}
          >
            <a className="text-link">Resend OTP?</a>
          </div>

          <button type="submit" className="submit-button">
            Get Started
          </button>

          <div className="terms-agreement" style={{ textAlign: "center" }}>
            By signing up to create an account I accept
            <br />
            Greattr's {""} Terms of Use & {""}
            <a href="/register" className="text-link">
              Privacy Policy.
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MerchantLogin;
