import PropTypes from "prop-types";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1.143rem;
  text-align: left;
  font-size: 1.144rem;
  color: var(--text-inverse);
  font-family: var(--font-text-small);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1.143rem;
`;

const Badge = styled.div`
  height: 1.856rem;
  width: 1.856rem;
  border-radius: 114.29px;
  background-color: var(--text-inverse);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.287rem 0.25rem 0.281rem 0.25rem;
  box-sizing: border-box;
`;

const BadgeImage = styled.img`
  height: 1.288rem;
  width: 1.288rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 50%; /* Makes it circular */
  background-color: white; /* Sets the background color to white */
`;

const Text = styled.div`
  font-size: 1.5rem;
  position: relative;
  line-height: 160%;
  font-weight: 500;
`;

const Column1 = ({
  className = "",
  badgeName,
  paperless,
  badgeName1,
  noCollateralFree,
}) => {
  return (
    <Container className={className}>
      <Row>
        <Badge name={badgeName}>
          <BadgeImage alt="" src="/richeckfill-4.svg" />
        </Badge>
        <Text>{paperless}</Text>
      </Row>
      <Row>
        <Badge name={badgeName1}>
          <BadgeImage alt="" src="/richeckfill-4.svg" />
        </Badge>
        <Text>{noCollateralFree}</Text>
      </Row>
    </Container>
  );
};

Column1.propTypes = {
  className: PropTypes.string,
  badgeName: PropTypes.string,
  paperless: PropTypes.string,
  badgeName1: PropTypes.string,
  noCollateralFree: PropTypes.string,
};

export default Column1;
