// src/utils/axiosConfig.js
import axios from "axios";

// Set the default headers for all Axios requests
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-API-Key"] = "W3A4F6G7H8J9K0L1";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Greattr-Application-Type"] = "web";

export default axios;
