import React, { useState } from "react";
import "./DistSafetyCheckBusinessDetails.scss";
import { GESRecords } from "../../Utilsx/Encrypt";
import greattrlogo from "../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../components/utils/axiosConfig";
import { maskAadhaar } from "../utils/validations";

import { validateEmail, validatePanNo, validateGstNumber } from "../utils/validations";
import { Spinner } from "react-bootstrap";
const DistSafetyCheckBusinessDetails = () => {
  const location = useLocation();

  const [locationState, setlocationState] = useState(() => {
    const savedState = localStorage.getItem("state");
    console.log(savedState);
    const { state } = location;
    console.log("state: ", state);
    return savedState ? JSON.parse(savedState) : { ...state };
  });

  console.log("state obj", locationState);
  const [agencyName, setAgencyName] = useState(locationState.agencyName || "");
  const [businessEmail, setBusinessEmail] = useState(locationState.email || "");
  const [gstNumber, setGstNumber] = useState("");
  const [businessPan, setBusinessPan] = useState("");
  const [udyamAadhar, setUdyamAadhar] = useState("");
  const [institutionType, setInstitutionType] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState({});
  let apiEN = process.env.REACT_APP_API_apiEN;
  const navigate = useNavigate();

  console.log(locationState.role);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!agencyName) newErrors.agencyName = "Agency Name is required";
    if (!businessEmail) newErrors.businessEmail = "Business Email is required";
    if (!gstNumber) newErrors.gstNumber = "GST Number is required";
    // if (!businessPan) newErrors.businessPan = "Business PAN is required";
    // if (!udyamAadhar) newErrors.udyamAadhar = "Udyam Aadhar is required";
    if (!institutionType)
      newErrors.institutionType = "Institution Type is required";
    if (!address) newErrors.address = "Address is required";
    if (!state) newErrors.state = "State is required";
    if (!city) newErrors.city = "City is required";
    if (!pincode) newErrors.pincode = "Pincode is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});

      const submitData = {
        mobile: locationState.MobileNumber,
        BusinessName: agencyName,
        email: businessEmail,
        GSTNumber: gstNumber,
        BusinessPAN: businessPan,
        Aadhaar: maskAadhaar(udyamAadhar.replace(/-/g, "")),
        InstitutionType: institutionType,
        business_Address: address,
        business_State: state,
        business_District: city,
        business_Pincode: pincode,
      };
      const EncrytedSubmitData = GESRecords(submitData, apiEN);
      try {
        setisLoading(true);

        if (!validateGstNumber(gstNumber)) {
          alert(
            "Please enter a valid GST number in the format 22AAAAA0000A1Z5"
          );
          return;
        }

        // if (!validatePanNo(businessPan)) {
        //   alert(
        //     "Please enter a valid Business PAN number in the format ABCDE1234F"
        //   );
        //   return;
        // }

        // if (!validateUdyamAadhar(udyamAadhar)) {
        //   alert("Please enter a valid 12 digit Udyam Aadhaar number");
        //   return;
        // }

        if (!validateEmail(businessEmail)) {
          alert("Please enter a valid email address");
          return;
        }
        const route =
          locationState.role === "Dist"
            ? "/UpdateMerchantBuisnessDetails"
            : "/UpdateDealerBuisnessDetails";
        const response = await axios.post(
          `${process.env.REACT_APP_API_LINK}${route}`,
          EncrytedSubmitData
        );
        if (response.data.duplicate) {
          alert(response.data.message);
        } else {
          // alert(`${locationState.role} data inserted successfully.`);
          navigate("/safetycheck/DistSafetyCheckUploadDocuments", {
            state: {
              agencyName,
              address,
              state,
              city,
              pincode,
              email: businessEmail,
              ...locationState,
            },
          });
        }
      } catch (error) {
        console.log(
          error.response?.data?.message ||
          "An error occurred while submitting the form."
        );
      } finally {
        isLoading(false);
      }
    }
    // navigate("/DistCompanyInfoOperator");
  };

  return (
    <div className="business-company-info">
      <div className="business-company-info-container">
        <img className="greattrlogo" src={greattrlogo} alt="" />
        <p className="paragraph-el">2 / 6</p>
        <h3 style={{ fontSize: 28 }}>Business Information</h3>
        <h4 style={{ fontSize: 16 }}>
          Welcome! Let's start by getting some details about your business.
        </h4>
        <h5 style={{ fontSize: 15 }}>
          This information will help us understand your operations better and
          ensure that we provide the best services tailored to your needs.
        </h5>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignSelf: "center",
            width: "100%",
          }}
        >
          <div className="form-row">
            <label className="label-font">
              Agency Name*
              <input
                type="text"
                value={agencyName}
                onChange={(e) => setAgencyName(e.target.value)}
                placeholder="Enter Agency Name"
                className="safety-check-placeholder-font"
                required
              />
              {errors.agencyName && (
                <span className="error">{errors.agencyName}</span>
              )}
            </label>
            <div>
              <label className="label-font">Business Email ID*</label>
              <input
                type="email"
                value={businessEmail}
                onChange={(e) => {
                  if (!validateEmail(e.target.value)) {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        businessEmail:
                          "Please enter a valid email ID",
                      };
                    });
                    return setBusinessEmail(e.target.value);
                  } else {
                    setErrors((prevObj) => {
                      return {
                        ...prevObj,
                        businessEmail: null,
                      };
                    });
                    return setBusinessEmail(e.target.value);
                  }
                }}
                placeholder="Enter Business Email ID"
                className="safety-check-placeholder-font"
                required
              />
              {errors.businessEmail && (
                <span className="error" style={{ fontSize: 16 }}>{errors.businessEmail}</span>
              )}
            </div>
          </div>
          <div className="form-row">
            <label className="label-font">GST Number*</label>
            <input
              type="text"
              value={gstNumber}
              onChange={(e) => {
                if (!validateGstNumber(e.target.value.toLocaleUpperCase())) {
                  setErrors((prevObj) => {
                    return {
                      ...prevObj,
                      gstNumber:
                        "Please enter a valid GST number in the format 22AAAAA0000A1Z5",
                    };
                  });
                  return setGstNumber(e.target.value.toLocaleUpperCase());
                } else {
                  setErrors((prevObj) => {
                    return {
                      ...prevObj,
                      gstNumber: null,
                    };
                  });
                  return setGstNumber(e.target.value.toLocaleUpperCase());
                }
              }}
              placeholder="Enter GST Number"
              className="safety-check-placeholder-font"
              required
            />
            {errors.gstNumber && (
              <span className="error" style={{ fontSize: 16 }}>{errors.gstNumber}</span>
            )}
            {/* <label className="label-font">Business PAN</label>
            <input
              type="text"
              value={businessPan}
              onChange={(e) =>
                setBusinessPan(e.target.value.toLocaleUpperCase())
              }
              placeholder="Enter Business PAN"
              className="safety-check-placeholder-font"
              required
            />
            {errors.businessPan && (
              <span className="error">{errors.businessPan}</span>
            )} */}
          </div>
          <div className="form-row">
            {/* <label className="label-font">
              Udyam Aadhar Number
              <input
                type="text"
                value={udyamAadhar}
                onChange={(e) =>
                  setUdyamAadhar(e.target.value.toLocaleUpperCase())
                }
                placeholder="Enter Udyam Aadhar Number"
                className="safety-check-placeholder-font"
              />
              {errors.udyamAadhar && (
                <span className="error">{errors.udyamAadhar}</span>
              )}
            </label> */}
            <label className="label-font">Institution Type*</label>
            <select
              value={institutionType}
              onChange={(e) => setInstitutionType(e.target.value)}
              placeholder="Select Institution Type"
              className="safety-check-placeholder-font"
              required
            >
              <option value="">Select Institution Type</option>

              <option value="SoleProprietorship">Proprietorship</option>
              <option value="Partnership">Partnership Firm</option>
              <option value="PrivateLTD">Private Limited</option>
              <option value="Partnership(LLP)">
                Limited Liability Partnership(LLP)
              </option>
            </select>
            {errors.institutionType && (
              <span className="error">{errors.institutionType}</span>
            )}
          </div>
          <div className="form-row">
            <label className="label-font">Address*</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter Address"
              className="safety-check-placeholder-font"
              required
            />
            {errors.address && <span className="error">{errors.address}</span>}
          </div>
          <div className="form-row">
            <label className="label-font">State*</label>
            <select
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder="Select State"
              className="safety-check-placeholder-font"
              required
            >
              <option value="" disabled>
                Select State
              </option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              <option value="Andaman and Nicobar Islands">
                Andaman and Nicobar Islands
              </option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Dadra and Nagar Haveli and Daman and Diu">
                Dadra and Nagar Haveli and Daman and Diu
              </option>
              <option value="Delhi">Delhi</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Puducherry">Puducherry</option>
            </select>
            {errors.state && <span className="error">{errors.state}</span>}
          </div>

          <div className="form-row">
            <label className="label-font">City*</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Select City"
              className="safety-check-placeholder-font"
              required
            />
            {errors.city && <span className="error">{errors.city}</span>}
          </div>

          <div className="form-row">
            <label className="label-font">Pincode*</label>
            <input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              placeholder="Enter Pincode"
              className="safety-check-placeholder-font"
              required
            />
            {errors.pincode && <span className="error">{errors.pincode}</span>}
          </div>
          <button className="business-button" type="submit">
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "Continue"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default DistSafetyCheckBusinessDetails;
