import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCloudUploadAlt, 
  faFile, 
  faTrash, 
  faExclamationCircle 
} from '@fortawesome/free-solid-svg-icons';
import greattrlogo from "../assets/logo.png";
import "./DistUploadDocuments.scss";
import docuinfo from "../assets/docinfo.png";
import axios from "../../components/utils/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../store";
import { GESRecords } from "../../Utilsx/Encrypt";

const DistUploadDocuments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const popNavStack = useStore((state) => state.popNavStack);
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedFiles, setSelectedFiles] = useState({
    selfie: null,
    PAN: null,
    BuisnessPan: null,
    GST: null,
    MSME: null,
    cheque: null
  });

  // Parse the DocumentReupload JSON string into an object
  const documentReupload = user.DocumentReupload ? JSON.parse(user.DocumentReupload) : {
    BuisnessPan: true,
    PAN: true,
    GST: true,
    MSME: true,
    selfie: true,
    cheque: true
  };

  console.log(documentReupload, "DocumentReupload in distupload");

  const documentTypes = {
    BuisnessPan: 'Business PAN*',
    PAN: 'Individual PAN*',
    GST: 'GST Certificate*',
    MSME: 'Udyam Certificate (MSME)*',
    selfie: 'Applicant Selfie OR Passport Size Photo*',
    cheque: 'Cancelled Cheque*' 
  };

  const fileSizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const isDuplicateFile = (newFile, type) => {
    return Object.entries(selectedFiles).some(([key, file]) => 
      key !== type && 
      file && 
      file.name === newFile.name && 
      file.size === newFile.size
    );
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    setError('');

    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setError('File size should be less than 5MB');
      return;
    }

    if (!file.type.match(/image\/(jpg|jpeg|png|gif|svg)|application\/pdf/)) {
      setError('Only images (JPG, JPEG, PNG, GIF, SVG) and PDF files are allowed');
      return;
    }

    if (isDuplicateFile(file, type)) {
      setError('This file has already been uploaded for another document');
      return;
    }

    setSelectedFiles(prev => ({
      ...prev,
      [type]: file
    }));
  };

  const handleDelete = (type) => {
    if (window.confirm('Are you sure you want to remove this file?')) {
      setSelectedFiles(prev => ({
        ...prev,
        [type]: null
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const allFilesSelected = Object.entries(selectedFiles).every(([type, file]) => 
      documentReupload[type] ? file !== null : true
    );

    if (!allFilesSelected) {
      setError('Please select all required files before submitting.');
      return;
    }
  
    const formData = new FormData();
  
    // Rename files based on their document type
    Object.keys(selectedFiles).forEach(key => {
      const file = selectedFiles[key];
      if (file) {
        const fileExtension = file.name.split('.').pop(); // Get the file extension
        const newFileName = `${key}.${fileExtension}`; // Example: pan.pdf, aadhaar.jpg
        const renamedFile = new File([file], newFileName, { type: file.type });
        formData.append(key, renamedFile);
      }
    });
  
    let apiEN = process.env.REACT_APP_API_apiEN;
  
    const Encryptedstate = GESRecords(
      {
        GreattrDealerID: user?.GreattrDealerID,
        GreattrDistID: user?.GreattrDistID,
        MobileNumber: user?.MobileNumber,
      },
      apiEN
    );
  
    Object.entries(Encryptedstate).forEach(([key, value]) => {
      formData.append(key, value);
    });
  
    try {
      setIsLoading(true);
  
      const route = user.role === "Dist" ? "/uploadMerchantDocuments" : "/uploadDealerDocuments";
      const url = new URL(`${process.env.REACT_APP_API_LINK}${route}`);
      url.searchParams.append("MobileNumber", Encryptedstate.MobileNumber);
      if (Encryptedstate?.GreattrDistID) {
        url.searchParams.append("GreattrDistID", Encryptedstate?.GreattrDistID);
      } else {
        url.searchParams.append("GreattrDealerID", Encryptedstate?.GreattrDealerID);
      }
  
      const response = await axios.post(url.toString(), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (response.status === 200 && response.data.results) {
        alert("Files uploaded successfully!");
  
        const nextNav = user.NavStack[0];

        if (nextNav) {
          popNavStack();
          navigate(nextNav);
        } else {
          navigate("/DistPersonalDetails", {
            state: {
              MobileNumber: user.MobileNumber,
              ...user,
            },
          });
        }
      } else {
        throw new Error("Upload failed or no files were uploaded");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderUploadBox = (type) => {
    const file = selectedFiles[type];
    const label = documentTypes[type];

    // Only render the upload box if the document needs to be re-uploaded
    if (!documentReupload[type]) {
      return null;
    }

    return (
      <div className="Dist-upload-box">
        <div className="Dist-upload-label">{label}</div>
        
        {!file ? (
          <div className="Dist-upload-dropzone">
            <input
              type="file"
              className="Dist-upload-input"
              onChange={(e) => handleFileChange(e, type)}
              accept="image/*,.pdf"
            />
            <FontAwesomeIcon 
              icon={faCloudUploadAlt} 
              size="2x" 
              className="Dist-upload-icon" 
            />
            <div className="Dist-upload-text">
              Drag and drop or <span className="Dist-upload-link">browse files</span>
            </div>
          </div>
        ) : (
          <div className="Dist-upload-preview">
            <div className="Dist-upload-file-info">
              <FontAwesomeIcon icon={faFile} className="Dist-upload-icon" />
              <div className="Dist-upload-file-details">
                <h6>{file.name}</h6>
                <p>{fileSizes(file.size)} • {new Date(file.lastModified).toLocaleDateString()}</p>
              </div>
            </div>
            <button 
              className="Dist-upload-delete"
              onClick={() => handleDelete(type)}
              type="button"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="Dist-upload-container">
      <div className="Dist-upload-wrapper">
        <img src={greattrlogo} alt="GreatTR Logo" className="Dist-upload-logo mx-auto d-block" />
        
        <div className="text-center">
          <div className="Dist-upload-progress">Step 3 of 6</div>
          <h1 style={{ fontSize: "3.5rem", fontWeight: "bold" }}>Document Upload</h1>
          <p style={{ fontSize: "1.8rem", fontWeight: "bold", marginBottom: "2rem" }}>
            To keep our platform safe and secure, we need to verify your business.
            Please upload the required documents. This helps us ensure compliance
            and authenticity.
          </p>
        </div>

        <img 
          src={docuinfo} 
          alt="Document Guidelines" 
          className="Dist-upload-info-image mx-auto d-block" 
        />

        {error && (
          <div className="Dist-upload-error">
            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="Dist-upload-grid">
            {Object.keys(documentTypes).map(type => renderUploadBox(type))}
          </div>

          <div className="text-center">
            <button
              type="submit"
              className="Dist-upload-button"
              disabled={isLoading || Object.entries(selectedFiles).some(([type, file]) => 
                documentReupload[type] ? !file : false
              )}
            >
              {isLoading ? (
                <Spinner 
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              ) : null}
              {isLoading ? 'Uploading...' : 'Continue'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DistUploadDocuments;