import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Column1 from "./Column1";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Section = styled.section`
  align-self: stretch;
  display: flex;
  background-color: #fff;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font-size: 4.569rem;
  color: #ffffff;
  font-family: var(--heading-h6);
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 360px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  flex: 1;
  background-color: #573a87;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6.856rem 5.375rem 0 5.375rem;
  box-sizing: border-box;
  gap: 1rem;

  @media (max-width: 825px) {
    padding: 4.438rem 1.313rem 0 1.313rem;
  }

  @media (max-width: 360px) {
    flex-direction: column;
    padding: 2rem 0.5rem;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
`;

const Content = styled.div`
  width: 37.431rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 2.431rem;
  min-width: 37.431rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 1.2rem;
  }

  @media (max-width: 360px) {
    width: 100%;
    min-width: 100%;
    align-items: center;
    text-align: center;
    padding: 0 1rem;
    gap: 1rem;
  }
`;

const Title = styled(motion.div)`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  gap: 1.5rem;
  max-width: 100%;

  @media (max-width: 825px) {
    gap: 1rem;
  }

  @media (max-width: 360px) {
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }
`;

const Headline = styled(motion.div)`
  width: 42.856rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  max-width: 115%;
  font-size: 7.569rem;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.03em;

  @media (max-width: 825px) {
    font-size: 3.625rem;
    line-height: 4rem;
  }

  @media (max-width: 360px) {
    width: 100%;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    align-items: center;
  }
`;

const Subtitle = styled(motion.div)`
  align-self: stretch;
  font-size: 1.888rem;
  font-weight: 500;
  line-height: 180%;
  color: #fff;

  @media (max-width: 450px) {
    font-size: 1rem;
    line-height: 1.875rem;
  }

  @media (max-width: 360px) {
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

const FeatureList = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
  gap: 3.568rem;
  max-width: 100%;
  font-size: 1.144rem;

  @media (max-width: 825px) {
    flex-wrap: wrap;
    gap: 1.8rem;
  }

  @media (max-width: 300px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StoreImage = styled.img`
  width: 100%;

  overflow: hidden;

  padding: 5rem;

  @media (max-width: 476px) {
    flex: 1;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 3rem;
  }

  @media (max-width: 360px) {
    width: 80%;
    height: auto;
    padding: 2rem;
  }
`;

const CTAImage = styled(motion.img)`
  align-self: center;
  justify-self: center;

  width: 40rem;
  max-height: 80%;
  overflow: hidden;
  max-width: 100%;
`;

const titleVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const imageVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
};

const FeatureGrid = ({ className = "" }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 476);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 476);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Section className={className}>
      <Container>
        <Content>
          <Title
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={titleVariants}
          >
            <Headline
              initial="hidden"
              animate={controls}
              variants={titleVariants}
            >
              Small Loans,
              <br />
              Big Changes
            </Headline>
            <Subtitle
              initial="hidden"
              animate={controls}
              variants={titleVariants}
            >
              Enjoy the convenience of easy payments
              <br />
              with low cost or in EMIs
            </Subtitle>
          </Title>
          <FeatureList
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={titleVariants}
          >
            <Column1
              badgeName="radioGroup-3"
              paperless="100% Paperless"
              noCollateralFree="No Collateral Free"
              initial="hidden"
              animate={controls}
              variants={titleVariants}
            />
            <Column1
              badgeName="radioGroup-4"
              paperless="Flexible Tenure"
              noCollateralFree="Manage Your Bills"
              initial="hidden"
              animate={controls}
              variants={titleVariants}
            />
          </FeatureList>
          <a
            href="https://play.google.com/store/apps/details?id=com.greattr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StoreImage loading="lazy" alt="" src="/google-store.svg" />
          </a>
        </Content>

        {/* Conditionally render CTAImage based on isMobile */}
        {!isMobile && (
          <CTAImage
            loading="lazy"
            alt=""
            src="/ctaimage.svg"
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={imageVariants}
          />
        )}
      </Container>
    </Section>
  );
};

FeatureGrid.propTypes = {
  className: PropTypes.string,
};

export default FeatureGrid;
